/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Language } from '../models/language';


/**
 * Class providing database methods for languages.
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageAccess {

  private languageCollection: AngularFirestoreCollection<Language>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
    this.languageCollection = afs.collection<Language>('languages');
  }

  /**
   * Returns all Languages.
   *
   * @returns the found Languages, otherwise empty list
   */
  public getAllLanguages(): Observable<Language[]> {
    return this.afs.collection<Language>('languages', ref => ref.orderBy('name'))
      .valueChanges().pipe(
        map((languagesJson) => {
          return languagesJson as Language[]; // plainToClass(Language, languagesJson as object[]);
        })
      );
  }

  /**
   * Returns a language.
   *
   * @param languageId the language ID
   * @returns the found language, otherwise undefined
   */
  public getLanguage(languageId: string): Observable<Language | undefined> {
    return this.languageCollection.doc(languageId).valueChanges().pipe(
      map((languageJson) => {
        return languageJson as Language; // plainToClass(Language, languageJson);
      })
    );
  }

  /**
   * Adds a language.
   *
   * @param language the language to add
   * @returns the added language
   */
  public async addLanguage(language: Language): Promise<Language> {
    if (!language.id) {
      language.id = this.afs.createId();
    }

    await this.languageCollection.doc(language.id).set(classToPlain(language) as Language, { merge: true });

    return language;
  }

  /**
   * Updates a language.
   *
   * @param language the language to update
   * @returns the updated language
   */
  public async updateLanguage(language: Language): Promise<Language> {
    await this.languageCollection.doc(language.id).set(classToPlain(language) as Language, { merge: true });

    return language;
  }

  /**
   * Removes languages.
   *
   * @param languageIds the IDs of the languages to remove
   */
  public async removeLanguages(languageIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const languageId of languageIds) {
      batch.delete(this.languageCollection.doc(languageId).ref);
    }

    await batch.commit();
  }
}
