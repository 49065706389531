/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import * as voucherCode from 'voucher-code-generator';


/**
 * Utility class for code generation.
 */
export class CodeUtils {

  /**
   * Creates a unique code for a LocationPlace.
   */
  static generateLocationPlaceCode(): string {
    const codes: string[] = voucherCode.generate({
      length: 6,
      count: 1,
      charset: '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'
    });

    return codes[0];
  }

  /**
   * Creates a unique code for a Discount.
   */
  static generateDiscountCode(): string {
    const codes: string[] = voucherCode.generate({
      length: 6,
      count: 1,
      charset: '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'
    });

    return codes[0];
  }

  /**
   * Creates a unique code for handing over an order.
   */
  static generateOrderAuthCode(): string {
    const codes: string[] = voucherCode.generate({
      length: 4,
      count: 1,
      charset: '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'
    });

    return codes[0];
  }
}
