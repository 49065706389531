/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], args: {terms: string, property: string}): any[] {
    if (!items) {
      return [];
    }
    if (!args.terms) {
      return items;
    }
    args.terms = args.terms.toLowerCase();

    return items.filter( item => {
      return item[args.property].toLowerCase().includes(args.terms);
    });
  }

}
