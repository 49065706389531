/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { Camera } from '@ionic-native/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../../environments/environment';
import { DropZoneDirective } from './directive/drop-zone.directive';
import { ImagePreviewDirective } from './directive/image-preview.directive';
import { CloudImageDirective } from './directive/cloud-image.directive';
import { IonselectAutoscrollDirective } from './directive/ionselect-autoscroll.directive';
import { SearchPipe } from './pipe/search.pipe';
import { FilterTextPipe } from './pipe/filter-text.pipe';
import { LocationFilterPipe } from './pipe/location-filter.pipe';
import { CategoryFilterPipe } from './pipe/category-filter.pipe';
import { TranslationPipe } from './pipe/translation.pipe';
import { LocationPricePipe } from './pipe/location-price.pipe';
import { MomentDatePipe } from './pipe/moment-date.pipe';
import { TimeDiffPipe } from './pipe/time-diff.pipe';
import { TimeFormatPipe } from './pipe/time-format.pipe';
import { ImageUploadComponent } from './upload/image-upload/image-upload.component';
import { FileUploadComponent } from './upload/file-upload/file-upload.component';
import { SelectDialogComponent } from './modal/select-dialog/select-dialog.component';
import { PriceEditComponent } from './modal/price-edit/price-edit.component';
import { TextEditComponent } from './modal/text-edit/text-edit.component';
import { AddressEditComponent } from './modal/address-edit/address-edit.component';
import { CatalogSelectComponent } from './modal/catalog-select/catalog-select.component';
import { TopItemsAnalyticsComponent } from '../analytics/top-items-analytics/top-items-analytics.component';
import { LanguageUsageAnalyticsComponent } from '../analytics/language-usage-analytics/language-usage-analytics.component';
import { QrUsageAnalyticsComponent } from '../analytics/qr-usage-analytics/qr-usage-analytics.component';
import { PageViewsAnalyticsComponent } from '../analytics/page-views-analytics/page-views-analytics.component';
import { ReservationUsageAnalyticsComponent } from '../analytics/reservation-usage-analytics/reservation-usage-analytics.component';
import { AppMissingTranslationHandler } from '../app-missing-translation-handler';


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  // use timestamp to prevent browser from caching
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + environment.app.version);
}

@NgModule({
  declarations: [
    DropZoneDirective,
    ImagePreviewDirective,
    CloudImageDirective,
    IonselectAutoscrollDirective,
    SearchPipe,
    FilterTextPipe,
    LocationFilterPipe,
    CategoryFilterPipe,
    TranslationPipe,
    LocationPricePipe,
    MomentDatePipe,
    TimeDiffPipe,
    TimeFormatPipe,
    ImageUploadComponent,
    FileUploadComponent,
    SelectDialogComponent,
    PriceEditComponent,
    TextEditComponent,
    AddressEditComponent,
    CatalogSelectComponent,
    TopItemsAnalyticsComponent,
    PageViewsAnalyticsComponent,
    LanguageUsageAnalyticsComponent,
    QrUsageAnalyticsComponent,
    ReservationUsageAnalyticsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler
      },
    }),
    QRCodeModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTreeModule,
    CdkTreeModule,
    ClipboardModule
  ],
  exports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    CloudImageDirective,
    IonselectAutoscrollDirective,
    SearchPipe,
    FilterTextPipe,
    LocationFilterPipe,
    CategoryFilterPipe,
    TranslationPipe,
    LocationPricePipe,
    MomentDatePipe,
    TimeDiffPipe,
    TimeFormatPipe,
    ImageUploadComponent,
    FileUploadComponent,
    SelectDialogComponent,
    PriceEditComponent,
    TextEditComponent,
    AddressEditComponent,
    CatalogSelectComponent,
    TopItemsAnalyticsComponent,
    PageViewsAnalyticsComponent,
    LanguageUsageAnalyticsComponent,
    QrUsageAnalyticsComponent,
    ReservationUsageAnalyticsComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatCardModule,
    MatSidenavModule,
    MatMenuModule,
    MatTreeModule,
    DragDropModule,
    CdkTreeModule,
    ClipboardModule
  ],
  entryComponents: [
    PriceEditComponent,
    TextEditComponent,
    AddressEditComponent,
    CatalogSelectComponent
  ],
  providers: [
    Geolocation,
    Camera,
    CurrencyPipe,
    LocationPricePipe
  ]
})
export class SharedModule {
}
