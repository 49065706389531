/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Order } from '../models/order';
import { OrderReceipt } from '../models/order-receipt';
import { OrderAccess } from '../access/order-access.service';
import { CodeUtils } from '../utils/code-utils';


/**
 * Class providing management methods for orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderManager {

  /**
   * The default constructor.
   */
  constructor(
    private orderAccess: OrderAccess
  ) {
  }

  /**
   * Returns all Orders of a Location.
   *
   * @param locationId the ID of the Location
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrders(locationId: string): Observable<Order[]> {
    return this.orderAccess.getAllOrders(locationId);
  }

  /**
   * Returns all Orders of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Orders, otherwise empty list
   */
  public getOrdersOfCustomer(customerId: string): Observable<Order[]> {
    return this.orderAccess.getOrdersOfCustomer(customerId);
  }

  /**
   * Returns all Orders of a location with fulfillments.
   *
   * @param locationId the ID of the location
   * @param fulfillmentTypes the orders with one of these specific fulfillment types
   * @param fromTime return orders newer than this time
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrdersWithFulfillment(locationId?: string, fulfillmentTypes?: string[], fromTime?: string): Observable<Order[]> {
    return this.orderAccess.getAllOrdersWithFulfillment(locationId, fulfillmentTypes, fromTime);
  }

  /**
   * Returns all Orders of an Event.
   *
   * @param eventId the ID of the Event
   * @returns the found Orders, otherwise empty list
   */
  public getAllOrdersOfEvent(eventId: string): Observable<Order[]> {
    return this.orderAccess.getAllOrdersOfEvent(eventId);
  }

  /**
   * Returns all placed Orders (including COMPLETED, CANCELLED and DEACTIVATED orders).
   *
   * @param locationId the ID of the location
   * @returns the found Orders
   */
  public getPlacedOnlyOrders(locationId: string): Observable<Order[]> {
    return this.orderAccess.getPlacedOnlyOrders(locationId);
  }

  /**
   * Returns all placed Orders between two dates (including COMPLETED, CANCELLED and DEACTIVATED orders).
   *
   * @param accountId the ID of the account the orders belong to
   * @param locationId the ID of the location the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getPlacedOrders(accountId?: string, locationId?: string, from?: string, to?: string): Observable<Order[]> {
    return this.orderAccess.getPlacedOrders(accountId, locationId, from, to);
  }

  /**
   * Returns all placed and open Orders between two dates.
   *
   * @param accountId the ID of the account the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getOpenOrders(accountId: string, from?: string, to?: string): Observable<Order[]> {
    return this.orderAccess.getOpenOrders(accountId, from, to);
  }

  /**
   * Returns all completed Orders between two dates.
   *
   * @param accountId the ID of the account the orders belong to
   * @param from the from date in RFC 3339 format
   * @param to the to date in RFC 3339 format
   * @returns the found Orders
   */
  public getCompletedOrders(accountId: string, from: string, to: string): Observable<Order[]> {
    return this.orderAccess.getCompletedOrders(accountId, from, to);
  }

  /**
   * Returns an Order specified by the ID.
   *
   * @param orderId the Order ID
   * @returns the found Order, otherwise undefined
   */
  public getOrder(orderId: string): Observable<Order> {
    return this.orderAccess.getOrder(orderId);
  }

  /**
   * Returns the Order specified by the cancellation code.
   *
   * @param cancellationCode the cancellation code
   * @returns the found Order, otherwise undefined
   */
  public getOrderByCancellationCode(cancellationCode: string): Observable<Order> {
    return this.orderAccess.getOrderByCancellationCode(cancellationCode);
  }

  /**
   * Adds an order to the specified event.
   *
   * @param order the order to add
   * @param userId the user ID
   * @param eventId the event ID
   * @returns the added order
   */
  public async addOrder(order: Order, userId?: string, eventId?: string): Promise<string> {
    if (userId) {
      order.userId = userId;
    }
    if (eventId) {
      order.eventId = eventId;
    }

    return this.orderAccess.addOrder(order);
  }

  /**
   * Updates the order of the specified event.
   *
   * @param order the order to update
   * @param eventId the event ID
   * @returns the updated order
   */
  public async updateOrder(order: Order, eventId?: string): Promise<string> {
    return this.orderAccess.updateOrder(order, eventId);
  }

  /**
   * Removes Orders.
   *
   * @param orderIds the IDs of the Orders to remove
   */
  public async removeOrders(orderIds: string[]): Promise<void> {
    return this.orderAccess.removeOrders(orderIds);
  }

  /**
   * Changes status of Order.
   *
   * @param orderIds the IDs of the Orders to change
   * @param status possible values are OPEN, COMPLETED, CANCELLED
   */
  public async setStatusOfOrders(orderIds: string[], status: string): Promise<void> {
    return this.orderAccess.setStatusOfOrders(orderIds, status);
  }

  /**
   * Changes status of the first OrderFulfillment of an Order.
   *
   * @param orderId the ID of the Order to change
   * @param status possible values are PROPOSED, RESERVED, PREPARED, COMPLETED, CANCELLED, FAILED
   */
  public async setStatusOfOrderFulfillment(orderId: string, status: string): Promise<void> {
    const order: Order = await this.getOrder(orderId).pipe(first()).toPromise();

    if (order.fulfillments && order.fulfillments.length > 0) {
      order.fulfillments[0].status = status;
    }

    await this.addOrder(order);
  }

  /**
   * Changes status of the first OrderFulfillment of an Order.
   *
   * @param orderId the ID of the Order to change
   * @param expectedAt the timestamp indicating when the fulfillment is expected to be completed, in RFC 3339 format
   */
  public async setStatusOfOrderFulfillmentToReserved(orderId: string, expectedAt: string): Promise<void> {
    const order: Order = await this.getOrder(orderId).pipe(first()).toPromise();

    if (order.fulfillments && order.fulfillments.length > 0) {
      order.fulfillments[0].status = 'RESERVED';
      order.fulfillments[0].expectedAt = expectedAt;
      order.fulfillments[0].authCode = CodeUtils.generateOrderAuthCode();
    }

    await this.addOrder(order);
  }

  /**
   * Returns an OrderReceipt specified by the ID.
   *
   * @param orderReceiptId the OrderReceipt ID
   * @returns the found OrderReceipt, otherwise undefined
   */
  public getOrderReceipt(orderReceiptId: string): Observable<OrderReceipt> {
    return this.orderAccess.getOrderReceipt(orderReceiptId);
  }

  /**
   * Add new OrderReceipt to cloud.
   *
   * @param orderReceipt the OrderReceipt object to add
   * @return the id of the new OrderReceipt
   */
  public async addOrderReceipt(orderReceipt: OrderReceipt): Promise<string> {
    return this.orderAccess.addOrderReceipt(orderReceipt);
  }

  /**
   * Removes OrderReceipts.
   *
   * @param orderReceiptIds the IDs of the OrderReceipts to remove
   */
  public async removeOrderReceipts(orderReceiptIds: string[]): Promise<void> {
    return this.orderAccess.removeOrderReceipts(orderReceiptIds);
  }

  /**
   * Sends a confirmation reminder.
   *
   * @param orderId the ID of the order object to remind for
   */
  public async sendConfirmationReminder(orderId: string): Promise<void> {
    return this.orderAccess.sendConfirmationReminder(orderId);
  }
}
