/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Directive, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { OverlayEventDetail } from '@ionic/core';

import { TranslateService } from '@ngx-translate/core';

import { AnalyticsManager } from './manager/analytics-manager.service';
import { BasePage } from './base.page';


/**
 * Base class for all form pages.
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseFormPage extends BasePage implements OnInit {

  private dataForm: FormGroup;

  /**
   * The default constructor.
   */
  protected constructor(
    protected pageName: string,
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager
  ) {
    super(pageName, translateService, alertController, loadingController, analyticsManager);
  }

  async ngOnInit() {
    this.setForm(this.createForm());
  }

  public setForm(dataForm: FormGroup): void {
    this.dataForm = dataForm;
  }

  public getForm(): FormGroup {
    return this.dataForm;
  }

  abstract createForm(): FormGroup;

  protected abstract async resetForm(): Promise<void>;

  abstract async saveForm(): Promise<void>;

  public async discardForm(): Promise<boolean> {
    return this.dataForm.dirty ? await this.showConfirmDialog() : true;
  }

  private async showConfirmDialog(): Promise<boolean> {
    const alert = await this.alertController.create({
      message: this.translateService.instant('APP.CONFIRM_DISCARD'),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel'
        }, {
          text: this.translateService.instant('APP.OK')
        }
      ]
    });

    await alert.present();

    return alert.onDidDismiss().then((event: OverlayEventDetail) => event.role !== 'cancel');
  }
}
