/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../models/user';
import { Account } from '../models/account';
import { AccountExtension } from '../models/account-extension';
import { AccountImage } from '../models/account-image';
import { AccountInvoice } from '../models/account-invoice';
import { CatalogItem } from '../models/catalog-item';
import { CatalogMenu } from '../models/catalog-menu';
import { Location } from '../models/location';
import { DatabaseAccess } from '../access/database-access.service';
import { AccountAccess } from '../access/account-access.service';
import { LocationManager } from './location-manager.service';
import { CatalogManager } from './catalog-manager.service';


/**
 * Class providing management methods for accounts.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountManager {

  /**
   * The default constructor.
   */
  constructor(
    private accountAccess: AccountAccess,
    private databaseAccess: DatabaseAccess,
    private locationManager: LocationManager,
    private catalogManager: CatalogManager
  ) {
  }

  /**
   * Returns all Accounts.
   *
   * @returns the found Accounts, otherwise empty list
   */
  public getAllAccounts(): Observable<Account[]> {
    return this.accountAccess.getAllAccounts();
  }

  /**
   * Returns a list of accounts.
   *
   * @param accountIds the account IDs
   * @returns the found accounts, otherwise undefined
   */
  public getAccounts(accountIds: string[]): Observable<Account[]> {
    return this.accountAccess.getAccounts(accountIds);
  }

  /**
   * Adds an account.
   *
   * @param account the account to add
   * @returns the added account
   */
  public async addAccount(account: Account): Promise<Account> {
    return this.accountAccess.addAccount(account);
  }

  /**
   * Updates an account.
   *
   * @param account the account to update
   * @returns the updated account
   */
  public async updateAccount(account: Account): Promise<Account> {
    return this.accountAccess.updateAccount(account);
  }

  /**
   * Removes an account.
   *
   * @param accountId the ID of the account to remove
   */
  public async removeAccount(accountId: string): Promise<void> {
    // remove references from user
    const users: User[] = await this.databaseAccess.getUsersOfAccount(accountId).pipe(first()).toPromise();
    for (const user of users) {
      user.accountIds = [];
      await this.databaseAccess.addUser(user);
    }

    const items: CatalogItem[] = await this.catalogManager.getAllCatalogItems(accountId).pipe(first()).toPromise();
    const menus: CatalogMenu[] = await this.catalogManager.getAllCatalogMenus(accountId).pipe(first()).toPromise();
    const locations: Location[] = await this.locationManager.getAllLocationsOfAccount(accountId).pipe(first()).toPromise();

    await this.catalogManager.removeCatalogItems(items.map((object: CatalogItem) => object.id));
    await this.catalogManager.removeCatalogMenus(menus.map((object: CatalogMenu) => object.id));
    await this.locationManager.removeLocations(locations.map((object: Location) => object.id));

    return this.accountAccess.removeAccounts([accountId]);
  }

  /**
   * Returns a list of account extensions.
   *
   * @param accountIds the account IDs
   * @returns the found account extensions, otherwise undefined
   */
  public getAccountExtensions(accountIds: string[]): Observable<AccountExtension[]> {
    return this.accountAccess.getAccountExtensions(accountIds);
  }

  /**
   * Adds an account extension.
   *
   * @param accountExtension the account extension to add
   * @returns the added account extension
   */
  public async addAccountExtension(accountExtension: AccountExtension): Promise<AccountExtension> {
    return this.accountAccess.addAccountExtension(accountExtension);
  }

  /**
   * Updates an account extension.
   *
   * @param accountExtension the account extension to update
   * @returns the updated account extension
   */
  public async updateAccountExtension(accountExtension: AccountExtension): Promise<AccountExtension> {
    return this.accountAccess.updateAccountExtension(accountExtension);
  }

  /**
   * Removes an account extension.
   *
   * @param accountId the ID of the account extension to remove
   */
  public async removeAccountExtension(accountId: string): Promise<void> {
    return this.accountAccess.removeAccountExtensions([accountId]);
  }

  /**
   * Add new AccountImage to cloud.
   *
   * @param accountImage the AccountImage object to add
   * @return the id of the new AccountImage
   */
  public async addAccountImage(accountImage: AccountImage): Promise<string> {
    return this.accountAccess.addAccountImage(accountImage);
  }

  /**
   * Returns the AccountImage specified by the ID.
   *
   * @param accountImageId the AccountImage ID
   * @returns the found AccountImage, otherwise undefined
   */
  public getAccountImage(accountImageId: string): Observable<AccountImage | undefined> {
    return this.accountAccess.getAccountImage(accountImageId);
  }

  /**
   * Removes AccountImages.
   *
   * @param accountImageIds the IDs of the AccountImages to remove
   */
  public async removeAccountImages(accountImageIds: string[]): Promise<void> {
    return this.accountAccess.removeAccountImages(accountImageIds);
  }

  /**
   * Returns an AccountInvoice specified by the ID.
   *
   * @param accountInvoiceId the AccountInvoice ID
   * @returns the found AccountInvoice, otherwise undefined
   */
  public getAccountInvoice(accountInvoiceId: string): Observable<AccountInvoice> {
    return this.accountAccess.getAccountInvoice(accountInvoiceId);
  }

  /**
   * Add new AccountInvoice to cloud.
   *
   * @param accountInvoice the AccountInvoice object to add
   * @return the id of the new AccountInvoice
   */
  public async addAccountInvoice(accountInvoice: AccountInvoice): Promise<string> {
    return this.accountAccess.addAccountInvoice(accountInvoice);
  }

  /**
   * Removes AccountInvoices.
   *
   * @param accountInvoiceIds the IDs of the AccountInvoices to remove
   */
  public async removeAccountInvoices(accountInvoiceIds: string[]): Promise<void> {
    return this.accountAccess.removeAccountInvoices(accountInvoiceIds);
  }
}
