/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Address } from '../models/address';
import { Location } from '../models/location';
import { DeliveryRule } from '../models/location';
import { AddressManager } from '../manager/address-manager.service';


/**
 * Utility class for Address operations.
 */
export class AddressUtils {

  private addressManager: AddressManager;

  /**
   * The default constructor.
   */
  constructor(addressManager: AddressManager) {
    this.addressManager = addressManager;
  }

  /**
   * Formats an address.
   *
   * @param address the address to format
   * @returns the formatted address
   */
  static getFormattedAddress(address: Address): string {
    const addressStrings: string[] = [];

    if (address.organization) {
      addressStrings.push(address.organization);
    }
    if (address.addressLine1) {
      addressStrings.push(address.addressLine1);
    }
    const placeStrings: string[] = [];
    if (address.postalCode) {
      placeStrings.push(address.postalCode);
    }
    if (address.locality) {
      placeStrings.push(address.locality);
    }
    if (placeStrings.length > 0) {
      addressStrings.push(placeStrings.join(' '));
    }
    if (address.countryName) {
      addressStrings.push(address.countryName);
    }

    return addressStrings.join(', ');
  }

  /**
   * Returns the DeliverRule matching the address.
   *
   * @param location the location which has to deliver
   * @param address the address to deliver to
   * @returns the matched rule, undefined if not found
   */
  public async getMatchedRule(location: Location, address: Address | undefined): Promise<DeliveryRule | undefined> {
    let matchedRule: DeliveryRule | undefined;

    if (address) {
      if (location.orderingDeliveryRules && location.orderingDeliveryRules.length > 0) {
        let linearDistance: number | undefined;
        let drivingDistance: {duration: number, distance: number} | undefined;

        // if location type is PHYSICAL and at least there is more than one delivery rule with a distance trigger
        // then calculate the distance from location to address
        if (location.type === 'PHYSICAL' && location.address
          && location.orderingDeliveryRules.findIndex(value => value.triggerType === 'DISTANCE_LINEAR_METERS') > -1) {
          linearDistance = await this.addressManager.computeLinearDistance(location.address.lat, location.address.lng, address.lat, address.lng);
        }
        if (location.type === 'PHYSICAL' && location.address
          && location.orderingDeliveryRules.findIndex(value => value.triggerType === 'DISTANCE_METERS' || value.triggerType === 'DISTANCE_MINUTES') > -1) {
          drivingDistance = await this.addressManager.computeDistance(location.address.lat, location.address.lng, address.lat, address.lng);
        }

        // find matching rule
        for (const rule of location.orderingDeliveryRules) {
          if (rule.triggerType === 'POSTAL_CODE' && rule.postalCodes) {
            if (rule.postalCodes.split(',').includes(address.postalCode)) {
              matchedRule = rule;
              break;
            }
          } else if (rule.triggerType === 'COUNTRY' && rule.countries) {
            if (rule.countries.split(',').includes(address.country)) {
              matchedRule = rule;
              break;
            }
          } else if (rule.triggerType === 'DISTANCE_LINEAR_METERS' && rule.distance !== undefined) {
            if (linearDistance !== undefined && linearDistance <= rule.distance) {
              matchedRule = rule;
              break;
            }
          } else if (rule.triggerType === 'DISTANCE_METERS' && rule.distance !== undefined) {
            if (drivingDistance !== undefined && drivingDistance.distance <= rule.distance) {
              matchedRule = rule;
              break;
            }
          } else if (rule.triggerType === 'DISTANCE_MINUTES' && rule.distance !== undefined) {
            if (drivingDistance !== undefined && (drivingDistance.duration / 60) <= rule.distance) {
              matchedRule = rule;
              break;
            }
          }
        }
      } else {
        // legacy
        if (location.orderingDeliveryArea && address.postalCode && location.orderingDeliveryArea.split(',').includes(address.postalCode)) {
          matchedRule = new DeliveryRule();
          matchedRule.triggerType = 'POSTAL_CODE';
          matchedRule.postalCodes = location.orderingDeliveryArea;
          matchedRule.minOrderValue = location.orderingDeliveryMinOrderValue;
          matchedRule.shipping = location.orderingDeliveryShipping;
        }
      }
    }

    return matchedRule;
  }
}
