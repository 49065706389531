/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { AnalyticsManager } from './manager/analytics-manager.service';
import { BaseFormPage } from './base-form.page';


/**
 * Base class for all modal form pages.
 * Special handling: Closing a modal with the back button:
 * https://dev.to/nicolus/closing-a-modal-with-the-back-button-in-ionic-5-angular-9-50pk
 *
 * TODO temporarily disabled the handling of window:popstate (back button of browser)
 * due to a problem with more than one modals in the stack
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseModalFormPage extends BaseFormPage implements OnInit, OnDestroy {

  /**
   * The default constructor.
   */
  protected constructor(
    protected pageName: string,
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    protected modalController: ModalController
  ) {
    super(pageName, translateService, alertController, loadingController, analyticsManager);
  }

  async ngOnInit() {
    await super.ngOnInit();

//    this.pushStateForModal();
  }

  ngOnDestroy() {
//    if (window.history.state.modal) {
//      history.back();
//    }
  }

//  @HostListener('window:popstate', ['$event'])
  public async discardForm(): Promise<boolean> {
    const discard: boolean = await super.discardForm();

    if (discard) {
      await this.closeModal();
//    } else {
//      this.pushStateForModal();
    }

    return discard;
  }

  public async closeModal(returnValue?: any): Promise<void> {
    await this.modalController.dismiss(returnValue);
  }

  private pushStateForModal(): void {
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }
}
