/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  appId: 'happz_business_dev',
  customerAppId: 'happz_dev',
  app: {
    version: '2.4.12-1',
    rootUrl: 'http://localhost:8101',
    defaultLang: 'de',
    pin: '3968',
    currentTermsVersion: '2021-09-01T00:00:00.000Z'
  },
  menuApp: {
    locationLink: 'http://localhost:8100/l/{{locationLinkName}}',
    reservationLink: 'http://localhost:8100/reservation/create/{{locationId}}',
    locationPlaceLink: 'http://localhost:8100/p/{{placeId}}',
    checkInLink: 'http://localhost:8100/l/{{locationLinkName}}/check-in',
    defaultTimezone: 'Europe/Berlin'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCvMGkxeUoZgaZUnJR6aATPIBqh3Xtfuus',
    authDomain: 'happz-dev.firebaseapp.com',
    databaseURL: 'https://happz-dev.firebaseio.com',
    projectId: 'happz-dev',
    storageBucket: 'happz-dev.appspot.com',
    messagingSenderId: '123751527969',
    appId: '1:123751527969:web:e340d42c4cb837e3'
  },
  auth: {
    useEmailVerification: true
  },
  signup: {
    defaultLang: 'de',
    defaultPricePlanId: 'menu_free'
  },
  dish: {
    predefinedTags: 'halal,koscher,vegan,vegetarisch,bio'
  },
  storage: {
    rootFolder: 'business-dev',
    userUploadFolder: 'user-upload'
  },
  firebaseStorageConfig: {
    imageRefName: 'firebase'
  },
  cloudinaryConfig: {
    cloudName: 'dhmeodtyu',
    unsignedUploadPreset: 'spztdszq',
    imageRefName: 'cloudinary'
  },
  stripePaymentConfig: {
    apiPublicKey: 'pk_test_QhP2AHSvSmpiJS1G6A1FY3Wh002fNVXDai',
    rootUrl: 'https://europe-west3-happz-dev.cloudfunctions.net',
  },
  firebaseEndpoint: {
    invoiceNumber: 'https://europe-west3-happz-dev.cloudfunctions.net/getNextInvoiceNumberEur',
    orderConfirmationReminder: 'https://europe-west3-happz-dev.cloudfunctions.net/sendConfirmationReminder'
  },
  accountBusinessTypeUi: {
    RESTAURANT: {
      id: 'RESTAURANT', langKeyPrefix: '', hidePreferredTime: false
    },
    SHOP: {
      id: 'SHOP', langKeyPrefix: 'SHOP.', hidePreferredTime: false
    },
    ACCOMMODATION: {
      id: 'ACCOMMODATION', langKeyPrefix: 'ACCOMMODATION.', hidePreferredTime: false
    },
    ACCOMMODATION_MANAGED: {
      id: 'ACCOMMODATION_MANAGED', langKeyPrefix: 'MANAGED.ACCOMMODATION.', hidePreferredTime: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
