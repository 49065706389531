/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { OrderService } from '../models/order-service';


/**
 * Class providing database methods for OrderServices.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderServiceAccess {

  private orderServiceCollection: AngularFirestoreCollection<OrderService>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
    this.orderServiceCollection = afs.collection<OrderService>('orderServices');
  }

  /**
   * Returns all OrderServices of a location.
   *
   * @param locationId the ID of the location
   * @returns the found OrderServices, otherwise empty list
   */
  public getAllOrderServices(locationId: string): Observable<OrderService[]> {
    return this.afs.collection<OrderService>('orderServices', ref => ref.where('locationId', '==', locationId))
      .valueChanges().pipe(
        map((orderServicesJson) => {
          return orderServicesJson as OrderService[]; // plainToClass(OrderService, orderServicesJson as object[]);
        })
      );
  }

  /**
   * Returns a OrderService.
   *
   * @param orderServiceId the OrderService ID
   * @returns the found OrderService, otherwise undefined
   */
  public getOrderService(orderServiceId: string): Observable<OrderService | undefined> {
    return this.orderServiceCollection.doc(orderServiceId).valueChanges().pipe(
      map((orderServiceJson) => {
        return orderServiceJson as OrderService; // plainToClass(OrderService, orderServiceJson);
      })
    );
  }

  /**
   * Adds an OrderService.
   *
   * @param orderService the OrderService to add
   * @returns the added OrderService
   */
  public async addOrderService(orderService: OrderService): Promise<OrderService> {
    if (!orderService.id) {
      orderService.id = this.afs.createId();
    }

    await this.orderServiceCollection.doc(orderService.id).set(classToPlain(orderService) as OrderService, { merge: true });

    return orderService;
  }

  /**
   * Updates a OrderService.
   *
   * @param orderService the OrderService to update
   * @returns the updated OrderService
   */
  public async updateOrderDirect(orderService: OrderService): Promise<OrderService> {
    await this.orderServiceCollection.doc(orderService.id).set(classToPlain(orderService) as OrderService, { merge: true });

    return orderService;
  }

  /**
   * Removes OrderServices.
   *
   * @param orderServiceIds the IDs of the OrderServices to remove
   */
  public async removeOrderServices(orderServiceIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const orderServiceId of orderServiceIds) {
      batch.delete(this.orderServiceCollection.doc(orderServiceId).ref);
    }

    await batch.commit();
  }
}
