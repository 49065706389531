/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { first, takeUntil } from 'rxjs/operators';
import { Observable, Subject, combineLatest } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';

import { environment } from '../../../environments/environment';
import { Location } from '../../shared/models/location';
import { AnalyticsReport } from '../../shared/models/analytics-report';
import { AnalyticsManager } from '../../shared/manager/analytics-manager.service';
import { BasePage } from '../../shared/base.page';


interface ChartRecord {
  id: string;
  label: string;
  data: number;
}

@Component({
  selector: 'app-qr-usage-analytics',
  templateUrl: './qr-usage-analytics.component.html',
  styleUrls: ['./qr-usage-analytics.component.scss'],
})
export class QrUsageAnalyticsComponent extends BasePage implements OnDestroy {

  @Input() set locations(locations: Location[]) { this.updateLocations(locations); }
  @Input() set selectedLocationId(selectedLocationId: string) { this.updateSelectedLocation(selectedLocationId); }

  @ViewChild('chartView', {static: true}) chartView;

  private stop$: Subject<boolean> = new Subject<boolean>();

  public numberOfDays = 30;
  public chart?: Chart;
  private colorArray: string[] = [
    '#EF7C7B', '#A1373B', '#F57C00', '#161A32', '#9CC766', '#FFC4FF', '#7986CB', '#4FC3F7',
    '#FFADAA', '#FF8A65', '#90A4AE', '#4DB6AC', '#6B9B37', '#FFB74D', '#A1887F', '#FFF176'
  ];

  private chartRecords: Map<string, ChartRecord[]> = new Map();

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager
  ) {
    super('analytics_qr_usage', translateService, alertController, loadingController, analyticsManager);
  }

  private updateLocations(locations: Location[]): void {
    this.chartRecords.clear();

    const toTime: Date = new Date();
    const fromTime: Date = new Date(toTime.getTime() - (this.numberOfDays * 24 * 60 * 60 * 1000));
    const toTimeString: string = toTime.toISOString().substring(0, toTime.toISOString().indexOf('T'));
    const fromTimeString: string = fromTime.toISOString().substring(0, fromTime.toISOString().indexOf('T'));

    for (const location of locations) {
      const chartPerLocation: ChartRecord[] = [];
      this.chartRecords.set(location.id, chartPerLocation);

      chartPerLocation.push({ id: 'with', label: this.translateService.instant('ANALYTICS_QR_USAGE.WITH_QR'), data: 0 });
      chartPerLocation.push({ id: 'without', label: this.translateService.instant('ANALYTICS_QR_USAGE.WITHOUT_QR'), data: 0 });
    }

    const jobs: Observable<AnalyticsReport[]>[] = [];
    for (const location of locations) {
      jobs.push(this.analyticsManager.getReports('location', location.id, environment.customerAppId, 'page', 'view',
        'location_details', undefined, undefined, 'day', fromTimeString, toTimeString).pipe(first()));
    }

    combineLatest(jobs).pipe(
      takeUntil(this.stop$)
    ).subscribe((results: (AnalyticsReport[])[]) => {
      for (const reports of results) {
        for (const report of reports) {
          const chartPerLocation: ChartRecord[] | undefined = this.chartRecords.get(report.idOfType);

          if (chartPerLocation === undefined) {
            console.log('Unknown location ID', report.idOfType);
          } else {
            const containQr: boolean = report.value.indexOf('ref=qr') > -1;

            chartPerLocation[containQr ? 0 : 1].data += report.count;
          }
        }
      }

//      this.colorArray = this.generateColorArray(10);

      this.updateSelectedLocation(locations[0].id);
    });
  }

  private updateSelectedLocation(selectedLocationId: string): void {
    if (this.chart !== undefined) {
      this.chart.destroy();
    }
    this.chart = this.createChart(this.chartRecords.get(selectedLocationId) ? this.chartRecords.get(selectedLocationId) : []);
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  private generateColorArray(num: number): string[] {
    const colorArray: string[] = [];

    for (let i = 0; i < num; i++) {
      colorArray.push('#' + Math.floor(Math.random() * 16777215).toString(16));
    }

    return colorArray;
  }

  private createChart(chartRecords: ChartRecord[]): Chart {
    const sortedList: ChartRecord[] = chartRecords;

    return new Chart(this.chartView.nativeElement, {
      type: 'pie',
      data: {
        labels: sortedList.map((record: ChartRecord) => record.label),
        datasets: [{
          label: this.translateService.instant('ANALYTICS_QR_USAGE.CHART_LABEL'),
          data: sortedList.map((record: ChartRecord) => record.data),
          backgroundColor: this.colorArray // 'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
        }]
      }
    });
  }
}
