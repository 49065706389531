/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { User } from '../models/user';
import { AnalyticsEvent } from '../models/analytics-event';
import { AnalyticsReport } from '../models/analytics-report';
import { AnalyticsAccess } from '../access/analytics-access.service';
import { SessionManager } from './session-manager.service';
import { environment } from '../../../environments/environment';


/**
 * Class providing management methods for analytics.
 */
@Injectable({
  providedIn: 'root'
})
export class AnalyticsManager {

  /**
   * The default constructor.
   */
  constructor(
    private analyticsAccess: AnalyticsAccess,
    private sessionManager: SessionManager
  ) {
  }

  /**
   * Logs a page view.
   *
   * @param pageLabel The page the event is created on. Useful for categorizing events (e.g. 'location_details').
   * @param pageValue A numeric value associated with the page (e.g. the location ID if it is the location details page).
   * @param value A numeric value associated with the event (e.g. the URL).
   * @param locationId The Location ID if relevant for tracking the event.
   */
  public async logPageView(pageLabel: string, pageValue?: string, value?: string, locationId?: string): Promise<void> {
    return this.logEvent(pageLabel, pageValue, 'page', 'view', undefined, value, locationId);
  }

  /**
   * Logs an event.
   *
   * @param pageLabel The page the event is created on. Useful for categorizing events (e.g. 'location_details').
   * @param pageValue A numeric value associated with the page (e.g. the location ID if it is the location details page).
   * @param category Typically the object that was interacted with (e.g. 'video').
   * @param action The type of interaction (e.g. 'play').
   * @param label Useful for categorizing events (e.g. 'Fall Campaign').
   * @param value A numeric value associated with the event (e.g. 42).
   * @param locationId The Location ID if relevant for tracking the event.).
   * @param weight Indicates the weight of this event in relation to others. Default is 1.
   */
  public async logEvent(pageLabel: string, pageValue: string | undefined, category: string, action: string,
                        label?: string, value?: string, locationId?: string, weight?: number): Promise<void> {
    const user: User | undefined = await this.sessionManager.getCurrentUser().pipe(first()).toPromise();

    const analyticsEvent: AnalyticsEvent = new AnalyticsEvent();
    analyticsEvent.source = environment.appId;
    analyticsEvent.version = environment.app.version;
    analyticsEvent.locationId = locationId ? locationId : null;
    analyticsEvent.userId = user ? user.id : null;
    analyticsEvent.pageLabel = pageLabel;
    analyticsEvent.pageValue = pageValue ? pageValue : null;
    analyticsEvent.category = category;
    analyticsEvent.action = action;
    analyticsEvent.label = label ? label : null;
    analyticsEvent.value = value ? value : null;
    analyticsEvent.weight = weight ? weight : null;

    return this.analyticsAccess.logEvent(analyticsEvent);
  }

  /**
   * Returns all AnalyticsReports.
   *
   * @returns the found AnalyticsReports, otherwise empty list
   */
  public getReports(type?: string, idOfType?: string, source?: string, category?: string, action?: string,
                    pageLabel?: string, pageValue?: string, limit?: number,
                    unit?: string, fromTime?: string, toTime?: string): Observable<AnalyticsReport[]> {
    return this.analyticsAccess.getReports(type, idOfType, source, category, action, pageLabel, pageValue, limit, unit, fromTime, toTime);
  }
}
