/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { User } from '../models/user';
import { SessionManager } from '../manager/session-manager.service';


/**
 * Guard restricts access to specific route if user is not logged in.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * The default constructor.
   */
  constructor(
    private navController: NavController,
    private sessionManager: SessionManager,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.sessionManager.isReady().pipe(
      switchMap(() => {
        return this.sessionManager.getCurrentUser();
      }),
      map((user: User | undefined) => {
        const isLoggedIn = user !== undefined;

        // TODO remove hardcoded URL checking
        if (!isLoggedIn && !this.router.url.startsWith('/order/edit/') && !this.router.url.startsWith('/reservation/edit/')) {
          this.navController.navigateRoot('/login/login');
        }

        return isLoggedIn;
      })
    );
  }
}
