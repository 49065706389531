/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterText'
})
export class FilterTextPipe implements PipeTransform {

  transform(items: any[], args: {selections: boolean[], propertyName: string, textAll?: string, textNone?: string}): string {
    const textAll: string = args.textAll ? args.textAll : 'All';
    const textNone: string = args.textNone ? args.textNone : 'NONE';

    if (!items) {
      return textNone;
    }
    if (!args.selections || args.selections.length !== items.length) {
      return textNone;
    }

    const selectedItems: string[] = items.filter( (item: any, index: number) => args.selections[index])
      .map((value: any) => value[args.propertyName]);

    return selectedItems.length === 0 ? textNone : selectedItems.length === items.length ? textAll : selectedItems.join(', ');
  }

}
