/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { AngularFireStorage } from '@angular/fire/storage';

import { environment } from '../../../environments/environment';
import { StorageFile } from '../models/storage-file';


/**
 * Class providing access methods for the Firebase storage.
 */
@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageAccess {

  public static readonly NAME = environment.firebaseStorageConfig.imageRefName;

  /**
   * The default constructor.
   */
  constructor(
    private storage: AngularFireStorage
  ) {
  }

  /**
   * Uploads a file to the cloud storage.
   *
   * @param file the file to upload
   * @param folder the folder the file to upload to, optional
   * @returns the uploaded StorageImage
   */
  public uploadFile(file: File, folder?: string): Promise<StorageFile> {
    return new Promise<StorageFile>((resolve, reject) => {
      const randomId = Math.random().toString(36).substring(2) + '_' + file.name;
      const filePath = folder ? folder + '/' + randomId : randomId;

      const task = this.storage.upload(filePath, file).then(async (snapshot) => {
        const image: StorageFile = new StorageFile();

        image.refs = [{ name: FirebaseStorageAccess.NAME, ref: filePath }];
        image.url = await snapshot.ref.getDownloadURL();

        resolve(image);
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * Uploads a file to the cloud storage.
   *
   * @param dataUrl the file as a based64 encoded data URL to upload
   * @param folder the folder the file to upload to, optional
   * @returns the uploaded StorageFile
   */
  public uploadFileByDataUrl(dataUrl: string, folder?: string): Promise<StorageFile> {
    return new Promise<StorageFile>((resolve, reject) => {
      const randomId = Math.random().toString(36).substring(2);
      const filePath = folder ? folder + '/' + randomId : randomId;

      const task = this.storage.ref(filePath).putString(dataUrl, 'data_url').then(async (snapshot) => {
        const image: StorageFile = new StorageFile();

        image.refs = [{ name: FirebaseStorageAccess.NAME, ref: filePath }];
        image.url = await snapshot.ref.getDownloadURL();

        resolve(image);
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * Removes files.
   *
   * @param storageFiles the files to remove
   */
  public async removeFiles(storageFiles: StorageFile[]): Promise<void> {
    for (const storageFile of storageFiles) {
      if (storageFile.refs && storageFile.refs.length > 0 && storageFile.refs[0].name === FirebaseStorageAccess.NAME) {
        await this.storage.ref(storageFile.refs[0].ref).delete().pipe(first()).toPromise();
      }
    }
  }
}
