/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';


/**
 * Own MissingTranslationHandler.
 */
export class AppMissingTranslationHandler implements MissingTranslationHandler {

  constructor(
  ) {
  }

  handle(params: MissingTranslationHandlerParams) {
    const key: string[] = params.key.split('.');
    if (key.length > 1) {
      key.shift();
      if (key.join('.')) {
        return params.translateService.get(key.join('.'), params.interpolateParams);
      }
    }

    return params.key;
  }
}
