/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Money } from './money';


/**
 * Class representing a line item modifier.
 */
export class OrderLineItemModifier {

  /** The catalog object id referencing CatalogModifier. */
  public modifierId: string;
  /** The name of the modifier. */
  public name: string;
  /**
   * The base price for the modifier.
   * basePriceMoney is required for ad hoc modifiers. If both modifierId and basePriceMoney are set,
   * basePriceMoney will override the predefined CatalogModifier price.
   */
  public basePriceMoney: Money;
  /**
   * (Read only) The total price of the item modifier for its line item.
   * This is the modifier's basePriceMoney multiplied by the line item's quantity.
   */
  public totalPriceMoney: Money;

}

/**
 * Represents a discount that applies to one or more line items in an order.
 */
export class OrderLineItemAppliedDiscount {

  /**
   * The uid of the discount the applied discount represents. Must reference a discount present in the order.discounts field.
   *
   * This field is immutable. To change which discounts apply to a line item, you must delete the discount
   * and re-add it as a new OrderLineItemAppliedDiscount.
   */
  public discountUid: string;
  /**
   * The amount of money applied by the discount to the line item.
   */
  public appliedMoney: Money;

}

/**
 * Represents a discount that applies to one or more line items in an order.
 */
export class OrderLineItemAppliedTax {

  /**
   * The uid of the tax for which this applied tax represents. Must reference a tax present in the order.taxes field.
   *
   * This field is immutable. To change which taxes apply to a line item, delete and add new OrderLineItemAppliedTaxes.
   */
  public taxUid: string;
  /**
   * The amount of money applied by the tax to the line item.
   */
  public appliedMoney: Money;

}

/**
 * Class representing a line item within a food order.
 */
export class OrderLineItem {

  public categoryId: string;
  public itemId: string;
  public itemVariationId: string;
  public userId?: string;

  /** The name of the line item. */
  public name: string;
  /** The name of the variation line item. */
  public variationName: string;

  /** The CatalogModifiers applied to this line item. */
  public modifiers: OrderLineItemModifier[];
  /**
   * The list of references to discounts applied to this line item. Each OrderLineItemAppliedDiscount has a discountUid
   * that references the uid of a top-level OrderLineItemDiscounts applied to the line item. On reads, the amount applied is populated.
   *
   * An OrderLineItemAppliedDiscount will be automatically created on every line item for all ORDER scoped discounts
   * that are added to the order. OrderLineItemAppliedDiscount records for LINE_ITEM scoped discounts must be added
   * in requests for the discount to apply to any line items.
   *
   * To change the amount of a discount, modify the referenced top-level discount.
   */
  public appliedDiscounts: OrderLineItemAppliedDiscount[];
  /**
   * The list of references to taxes applied to this line item. Each OrderLineItemAppliedTax has a taxUid
   * that references the uid of a top-level OrderLineItemTax applied to the line item. On reads, the amount applied is populated.
   *
   * An OrderLineItemAppliedTax will be automatically created on every line item for all ORDER scoped taxes
   * added to the order. OrderLineItemAppliedTax records for LINE_ITEM scoped taxes must be added
   * in requests for the tax to apply to any line items.
   *
   * To change the amount of a tax, modify the referenced top-level tax.
   */
  public appliedTaxes: OrderLineItemAppliedTax[];
  /**
   * The quantity purchased, as a string representation of a number.
   * This string must have a positive integer value.
   */
  public quantity = 0;

  /** Customer's special requests. */
  public specialRequests?: string;

  // TODO DEPRECATED: use basePriceMoney instead
  public priceMoney: Money = new Money();
  /** The base price for a single unit of the line item. Does not include modifiers. */
  public basePriceMoney: Money = new Money();
  /**
   * The total price of all item variations sold in this line item.
   * Calculated as basePriceMoney multiplied by quantity. Does not include modifiers.
   */
  public variationTotalPriceMoney: Money = new Money();
  /**
   * The total price of all modifiers sold in this line item.
   * Calculated as sum of OrderLineItemModifier.totalPriceMoney. Does not include variations.
   */
  public modifierTotalPriceMoney: Money = new Money();
  /**
   * The amount of money made in gross sales for this line item.
   * Calculated as the sum of the variation's total price and each modifier's total price.
   */
  public grossSalesMoney: Money = new Money();
  /** The total discount amount of money to collect for the line item. */
  public totalDiscountMoney: Money = new Money();
  /** The total tax amount of money to collect for the line item. */
  public totalTaxMoney: Money = new Money();
  /** The total amount of money to collect for this line item. */
  public totalMoney: Money = new Money();
}
