/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { StorageFile } from './storage-file';


/**
 * An image file.
 */
export class StorageImage extends StorageFile {
  /** A caption that describes what is shown in the image. */
  public caption: string;
}
