/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';

import { AccountExtension } from '../models/account-extension';
import { AccountManager } from '../manager/account-manager.service';


/**
 * Utility class for the OrderYOYO.
 */
export class ConsentUtils {

  public static readonly ORDERYOYO_TERMS_VERSION = '2021-09-01T00:00:00.000Z';

  static async showTermsConsentPrompt(alertController: AlertController, translateService: TranslateService, accountManager: AccountManager,
                                      accountExt: AccountExtension): Promise<void> {
    const alert = await alertController.create({
      header: translateService.instant('CONSENT.TITLE'),
      message: translateService.instant('CONSENT.TEXT'),
      buttons: [
        {
          text: translateService.instant('CONSENT.BUTTON_CANCEL'),
          role: 'cancel',
          cssClass: 'consent-cancel-button',
          handler: async () => {
            await alertController.dismiss();
          }
        }, {
          text: translateService.instant('CONSENT.BUTTON_CONFIRM'),
          cssClass: 'consent-confirm-button',
          handler: async () => {
            accountExt.termsVersion = ConsentUtils.ORDERYOYO_TERMS_VERSION;
            await accountManager.updateAccountExtension(accountExt);
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  static async showTermsConfirmedPrompt(alertController: AlertController, translateService: TranslateService): Promise<void> {
    const alert = await alertController.create({
      header: translateService.instant('CONSENT.TITLE'),
      message: translateService.instant('CONSENT.CONFIRMED_TEXT'),
      buttons: [
        {
          text: translateService.instant('APP.OK')
        }
      ]
    });

    await alert.present();
  }
}
