/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { User } from '../models/user';
import { DatabaseAccess } from '../access/database-access.service';


/**
 * Class providing management methods for users.
 */
@Injectable({
  providedIn: 'root'
})
export class UserManager {

  /**
   * The default constructor.
   */
  constructor(
    private databaseAccess: DatabaseAccess
  ) {
  }

  /**
   * Returns all users of the specified account.
   *
   * @param accountId the account ID
   * @returns all users of the account, otherwise empty list
   */
  public getUsersOfAccount(accountId: string): Observable<User[]> {
    return this.databaseAccess.getUsersOfAccount(accountId);
  }

  /**
   * Returns all users of the specified event.
   *
   * @param eventId the event ID
   * @returns all users of the event, otherwise empty list
   */
  public getUsersOfEvent(eventId: string): Observable<User[]> {
    return this.databaseAccess.getUsersOfEvent(eventId);
  }

  /**
   * Returns all users.
   *
   * @returns the found users, otherwise empty list
   */
  public getAllUsers(): Observable<User[]> {
    return this.databaseAccess.getAllUsers();
  }

  /**
   * Returns a user.
   *
   * @param userId the user ID
   * @returns the found user, otherwise undefined
   */
  public getUser(userId: string): Observable<User | undefined> {
    return this.databaseAccess.getUser(userId);
  }

  /**
   * Returns a user by looking for its email address.
   *
   * @param email the email address of the user
   * @returns the found user, otherwise undefined
   */
  public getUserByEmail(email: string): Observable<User | undefined> {
    return this.databaseAccess.getUserByEmail(email);
  }

  /**
   * Adds a user.
   *
   * @param user the user to add
   * @returns the added user
   */
  public async addUser(user: User): Promise<User> {
    return this.databaseAccess.addUser(user);
  }

  /**
   * Updates a user.
   *
   * @param user the user to update
   * @returns the updated user
   */
  public async updateUser(user: User): Promise<User> {
    return this.databaseAccess.updateUser(user);
  }

  /**
   * Removes users.
   *
   * @param userIds the IDs of the users to remove
   */
  public async removeUsers(userIds: string[]): Promise<void> {
    return this.databaseAccess.removeUsers(userIds);
  }
}
