/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Account } from '../../shared/models/account';
import { User } from '../../shared/models/user';
import { UserManager } from '../../shared/manager/user-manager.service';
import { AccountManager } from '../../shared/manager/account-manager.service';
import { SessionManager } from '../../shared/manager/session-manager.service';
import { AnalyticsManager } from '../../shared/manager/analytics-manager.service';
import { BaseModalFormPage } from '../../shared/base-modal-form.page';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent extends BaseModalFormPage implements OnInit {

  public userId?: string;
  public loggedInUser?: User;
  public accounts?: Account[];

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    protected modalController: ModalController,
    private formBuilder: FormBuilder,
    private sessionManager: SessionManager,
    private userManager: UserManager,
    private accountManager: AccountManager
  ) {
    super('user_details', translateService, alertController, loadingController, analyticsManager, modalController);
  }

  ionViewDidEnter() {
    this.logPageView(this.pageLabel, this.userId);
  }

  async ngOnInit() {
    await super.ngOnInit();

    this.loggedInUser = await this.sessionManager.getCurrentUser().pipe(first()).toPromise();
    this.accounts = this.loggedInUser && this.loggedInUser.isAdmin ? await this.accountManager.getAllAccounts().pipe(first()).toPromise() : [];

    if (this.userId) {
      await this.resetForm();
    } else {

      const [account] = await this.sessionManager.getCurrentAccount().pipe(first()).toPromise();
      if (account) {
        this.getForm().get('accountIds').setValue([account.id]);
      }
    }
  }

  public createForm(): FormGroup {
    return this.formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      nickName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      accountIds: [[]],
      isAdmin: [false]
    });
  }

  public async resetForm(): Promise<void> {
    const user: User = await this.userManager.getUser(this.userId).pipe(first()).toPromise();

    this.getForm().reset({
      salutation: user.salutation ? user.salutation : 'empty',
      firstname: user.firstname,
      lastname: user.lastname,
      nickName: user.nickName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      accountIds: user.accountIds,
      isAdmin: user.isAdmin
    });
  }

  public async saveForm(): Promise<void> {
    if (!this.getForm().valid) {
      return;
    }

    if (!this.userId) {
      const userWithSameEmail: User | undefined = await this.userManager.getUserByEmail(this.getForm().value.email).pipe(first()).toPromise();
      if (userWithSameEmail) {
        this.showError(undefined, 'Email address already in use');
        return;
      }
    }

    let user: User;
    if (this.userId) {
      user = await this.userManager.getUser(this.userId).pipe(first()).toPromise();
    } else {
      user = new User();
    }

    user.accountIds = this.getForm().value.accountIds;
    user.salutation = this.getForm().value.salutation === 'empty' ? '' : this.getForm().value.salutation;
    user.firstname = this.getForm().value.firstname;
    user.lastname = this.getForm().value.lastname;
    user.nickName = this.getForm().value.nickName;
    user.email = this.getForm().value.email;
    user.phoneNumber = this.getForm().value.phoneNumber;
    user.isAdmin = this.getForm().value.isAdmin;

    this.logEvent('user', user.id ? 'update' : 'add', user.email, user.id);
    await this.userManager.addUser(user);

    await this.closeModal();
  }
}
