/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';


interface Sound {
  key: string;
  asset: string;
}

/**
 * Class providing methods to play audio.
 */
@Injectable({
  providedIn: 'root'
})
export class AudioAccess {

  private audioPlayer: HTMLAudioElement = new Audio();
  private sounds: Sound[] = [];

  /**
   * The default constructor.
   */
  constructor(
  ) {
  }

  /**
   * Initializes the audio player on user interaction to enable auto-play.
   */
  public async initAudio(): Promise<void> {
    // audio is only auto-played on iOS after first user interaction,
    // see https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
    this.audioPlayer.src = '';
    this.audioPlayer.removeAttribute('src');
    await this.audioPlayer.play();
  }

  /**
   * Preloads an audio asset.
   *
   * @param key the key of the preloaded audio asset
   * @param asset the path to the audio asset
   */
  public preload(key: string, asset: string): void {
    const audioPlayer = new Audio();
    audioPlayer.src = asset;

    this.sounds.push({
      key,
      asset
    });
  }

  /**
   * Plays a preloaded audio asset.
   *
   * @param key the key of the preloaded audio asset
   */
  public async play(key: string): Promise<void> {
    const soundToPlay: Sound | undefined = this.sounds.find((sound: Sound) => {
      return sound.key === key;
    });

    if (soundToPlay) {
      this.audioPlayer.src = soundToPlay.asset;
      await this.audioPlayer.play();
    }
  }
}
