/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageImage } from '../models/storage-image';
import { StorageFile } from '../models/storage-file';
import { FirebaseStorageAccess } from '../access/firebase-storage-access.service';
import { CloudinaryStorageAccess } from '../access/cloudinary-storage-access.service';


/**
 * Class providing management methods for the cloud storage.
 */
@Injectable({
  providedIn: 'root'
})
export class CloudStorageManager {

  /**
   * The default constructor.
   */
  constructor(
    private firebaseStorageAccess: FirebaseStorageAccess,
    private cloudinaryStorageAccess: CloudinaryStorageAccess
  ) {
  }

  /**
   * Adds a file to the cloud storage.
   *
   * @param file the file to upload
   * @returns the uploaded file
   */
  public async uploadFile(file: File): Promise<StorageFile> {
    return this.firebaseStorageAccess.uploadFile(file, environment.storage.rootFolder + '/' + environment.storage.userUploadFolder);
  }

  /**
   * Adds an image to the cloud storage.
   *
   * @param file the image to upload
   * @returns the uploaded image
   */
  public async uploadImage(file: File): Promise<StorageImage> {
    return await this.cloudinaryStorageAccess.uploadFile(file, environment.storage.rootFolder) as StorageImage;
  }

  /**
   * Adds an image to the cloud storage.
   *
   * @param dataUrl the file as a based64 encoded data URL to upload
   * @returns the uploaded image
   */
  public async uploadImageByDataUrl(dataUrl: string): Promise<StorageImage> {
    return await this.cloudinaryStorageAccess.uploadFileByDataUrl(dataUrl, environment.storage.rootFolder) as StorageImage;
  }

  /**
   * Removes files.
   *
   * @param storageFiles the StorageFiles to remove
   */
  public async removeFiles(storageFiles: StorageFile[]): Promise<void> {
    const toRemoveCloudinaryFiles: StorageFile[] = [];
    const toRemoveFirebaseFiles: StorageFile[] = [];

    for (const storageFile of storageFiles) {
      if (storageFile.refs && storageFile.refs.length > 0) {
        if (storageFile.refs[0].name === CloudinaryStorageAccess.NAME) {
          toRemoveCloudinaryFiles.push(storageFile);
        } else if (storageFile.refs[0].name === FirebaseStorageAccess.NAME) {
          toRemoveFirebaseFiles.push(storageFile);
        }
      }
    }

    await this.cloudinaryStorageAccess.removeFiles(toRemoveCloudinaryFiles);
    await this.firebaseStorageAccess.removeFiles(toRemoveFirebaseFiles);
  }
}
