/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import * as moment from 'moment-timezone';


/**
 * Utility class for Calendar operations.
 */
export class CalendarUtils {

  /**
   * Converts minutes to HH:mm.
   *
   * @param timeInMinutes time in minutes
   * @returns the time as HH:mm
   */
  static convertMinutesToTime(timeInMinutes: number): string {
    const hours = (timeInMinutes / 60);
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);

    return rHours.toString().padStart(2, '0') + ':' + rMinutes.toString().padStart(2, '0');
  }

  /**
   * Converts HH:mm to minutes.
   *
   * @param timeString time as HH:mm
   * @returns the time in minutes: HH*60 + mm
   */
  static convertTimeToMinutes(timeString: string): number {
    const time: string[] = timeString.split(':');

    return (+time[0] * 60) + (+time[1]);
  }

  /**
   * Changes (restaurant's local) time to user's local time.
   *
   * @param dateIsoString the time to change
   * @returns the changed time
   */
  static changeTimezoneToLocalTime(dateIsoString: string) {
    return moment(moment(dateIsoString).format(), 'YYYY-MM-DDTHH:mm').toISOString();
  }

  /**
   * Changes user's local time to (restaurant's local) time.
   *
   * @param dateIsoString the time to change
   * @param timezone the timezone to change to
   * @returns the changed time
   */
  static changeTimezoneOfLocalTime(dateIsoString: string, timezone: string) {
    return moment(moment(dateIsoString).format(), 'YYYY-MM-DDTHH:mm', timezone).toISOString();
  }
}
