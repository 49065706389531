/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Account, AccountBusinessTypeConstants } from '../../shared/models/account';
import { AccountExtension } from '../../shared/models/account-extension';
import { AccountImage } from '../../shared/models/account-image';
import { StorageImage } from '../../shared/models/storage-image';
import { User } from '../../shared/models/user';
import { Language } from '../../shared/models/language';
import { Money } from '../../shared/models/money';
import { AccountManager } from '../../shared/manager/account-manager.service';
import { AnalyticsManager } from '../../shared/manager/analytics-manager.service';
import { SessionManager } from '../../shared/manager/session-manager.service';
import { LanguageManager } from '../../shared/manager/language-manager.service';
import { BaseModalFormPage } from '../../shared/base-modal-form.page';
import { TextEditComponent } from '../../shared/modal/text-edit/text-edit.component';
import { ImageUploadComponent } from '../../shared/upload/image-upload/image-upload.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent extends BaseModalFormPage implements OnInit {

  @ViewChildren(ImageUploadComponent) imageUploads: QueryList<ImageUploadComponent>;

  @Input() accountId: string;

  public businessTypes = AccountBusinessTypeConstants;

  public isFormReady = false;
  public loggedInUser?: User;
  public languages: Language[];
  public images: AccountImage[] = [];

  constructor(
    protected translateService: TranslateService,
    protected alertController: AlertController,
    protected loadingController: LoadingController,
    protected analyticsManager: AnalyticsManager,
    private sessionManager: SessionManager,
    protected modalController: ModalController,
    private formBuilder: FormBuilder,
    private accountManager: AccountManager,
    private languageManager: LanguageManager
  ) {
    super('account_details', translateService, alertController, loadingController, analyticsManager, modalController);
  }

  ionViewDidEnter() {
    this.logPageView(this.pageLabel, this.accountId);
  }

  async ngOnInit() {
    await super.ngOnInit();

    this.loggedInUser = await this.sessionManager.getCurrentUser().pipe(first()).toPromise();
    this.languages = await this.languageManager.getAllLanguages().pipe(first()).toPromise();

    if (this.accountId) {
      await this.resetForm();
    }

    this.isFormReady = true;
  }

  public createForm(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      businessType: ['', Validators.required],
      defaultLang: ['', Validators.required],
      defaultCurrency: ['EUR', Validators.required],  // TODO should be dependent on customer's data
      imageUrls: [[]],
      invoiceMode: [{ value: 'POST_PAYOUT', disabled: true }, Validators.required],
//      invoiceMode: ['POST_PAYOUT', Validators.required],  // TODO disabled invoice mode other than POST_PAYOUT since not supported yet
      stripePaymentRecipientId: [''],
      paypalPaymentRecipientId: [''],
      paymentDirectCharges: [false],
      paymentDineInCommissionAmountMoney: [0],
      paymentDineInCommissionPercent: [0.9],
      paymentPickupCommissionAmountMoney: [0],
      paymentPickupCommissionPercent: [5.5],
      paymentShipmentCommissionAmountMoney: [0],
      paymentShipmentCommissionPercent: [5.5],
      paymentTransactionFeeAmountMoney: [0.25],
      paymentTransactionFeePercent: [3],
      ordering: [false]
    });
  }

  public async resetForm(): Promise<void> {
    this.isFormReady = false;

    const accounts: Account[] = await this.accountManager.getAccounts([this.accountId]).pipe(first()).toPromise();
    const accountExtensions: AccountExtension[] = await this.accountManager.getAccountExtensions([this.accountId]).pipe(first()).toPromise();

    if (accounts.length > 0 && accountExtensions.length > 0) {
      const account: Account = accounts[0];
      const accountExt: AccountExtension = accountExtensions[0];

      this.getForm().reset({
        id: account.id,
        name: account.name,
        businessType: account.businessType ? account.businessType : AccountBusinessTypeConstants.RESTAURANT,
        defaultLang: account.defaultLang,
        defaultCurrency: account.defaultCurrency,
        imageUrls: [],
        invoiceMode: accountExt.invoiceMode ? accountExt.invoiceMode : 'POST_PAYOUT',
        stripePaymentRecipientId: '',
        paypalPaymentRecipientId: '',
        paymentDirectCharges: accountExt.paymentDirectCharges ? accountExt.paymentDirectCharges : false,
        paymentDineInCommissionAmountMoney: accountExt.paymentDineInCommissionAmountMoney ? accountExt.paymentDineInCommissionAmountMoney.amount : 0,
        paymentDineInCommissionPercent: accountExt.paymentDineInCommissionPercent !== undefined ? accountExt.paymentDineInCommissionPercent : 0.9,
        paymentPickupCommissionAmountMoney: accountExt.paymentPickupCommissionAmountMoney ? accountExt.paymentPickupCommissionAmountMoney.amount : 0,
        paymentPickupCommissionPercent: accountExt.paymentPickupCommissionPercent !== undefined ? accountExt.paymentPickupCommissionPercent : 5.5,
        paymentShipmentCommissionAmountMoney: accountExt.paymentShipmentCommissionAmountMoney ? accountExt.paymentShipmentCommissionAmountMoney.amount : 0,
        paymentShipmentCommissionPercent: accountExt.paymentShipmentCommissionPercent !== undefined ? accountExt.paymentShipmentCommissionPercent : 5.5,
        paymentTransactionFeeAmountMoney: accountExt.paymentTransactionFeeAmountMoney ? accountExt.paymentTransactionFeeAmountMoney.amount : 0.25,
        paymentTransactionFeePercent: accountExt.paymentTransactionFeePercent !== undefined ? accountExt.paymentTransactionFeePercent : 3,
        ordering: accountExt.ordering ? accountExt.ordering : false
      });

      if (accountExt.paymentRecipient && accountExt.paymentRecipient.length > 0) {
        for (const recipient of accountExt.paymentRecipient) {
          if (recipient.name === 'stripe') {
            this.getForm().get('stripePaymentRecipientId').setValue(recipient.ref);
          } else if (recipient.name === 'paypal') {
            this.getForm().get('paypalPaymentRecipientId').setValue(recipient.ref);
          }
        }
      }

      if (account.imageIds) {
        for (const imageId of account.imageIds) {
          const image: AccountImage = await this.accountManager.getAccountImage(imageId).pipe(first()).toPromise();
          if (image) {
            this.images.push(image);
            this.getForm().get('imageUrls').value.push(image.url);
          }
        }
      }
    }

    this.isFormReady = true;
  }

  public changeImage(index: number, image?: StorageImage): void {
    if (image === undefined) {
      this.getForm().get('imageUrls').value.splice(index, 1);
      this.images.splice(index, 1);
    } else {
      if (index >= this.images.length) {
        this.getForm().get('imageUrls').value.push(image.url);
        this.images.push(image);
      } else {
        this.getForm().get('imageUrls').value.fill(image.url, index, index + 1);
        this.images.fill(image, index, index + 1);
      }
    }

    this.getForm().get('imageUrls').markAsDirty();
  }

  public async saveForm(): Promise<void> {
    if (!this.getForm().valid) {
      return;
    }
    if (this.imageUploads.find((imageUpload: ImageUploadComponent) => imageUpload.isImageUploading)) {
      await this.showError('', this.translateService.instant('UPLOAD_IMAGE.IS_UPLOADING'));
      return;
    }

    let account: Account;
    let accountExt: AccountExtension;
    if (this.accountId) {
      const accounts: Account[] = await this.accountManager.getAccounts([this.accountId]).pipe(first()).toPromise();
      const accountExts: AccountExtension[] = await this.accountManager.getAccountExtensions([this.accountId]).pipe(first()).toPromise();
      account = accounts.length > 0 ? accounts[0] : new Account();
      accountExt = accountExts.length > 0 ? accountExts[0] : new AccountExtension();
    } else {
      account = new Account();
      accountExt = new AccountExtension();
      accountExt.termsVersion = environment.app.currentTermsVersion;
    }

    // save AccountImages
    account.imageIds = [];
    for (const image of this.images) {
      if (this.accountId) {
        image.accountId = this.accountId;
      }
      const imageId: string = await this.accountManager.addAccountImage(image);
      this.logEvent('account_image', 'add', image.url, imageId);
      account.imageIds.push(imageId);
    }

    // save Account
    account.name = this.getForm().value.name;
    account.businessType = this.getForm().value.businessType;
    account.defaultLang = this.getForm().value.defaultLang;
    account.defaultCurrency = this.getForm().value.defaultCurrency;
    accountExt.invoiceMode = this.getForm().getRawValue().invoiceMode;
    accountExt.paymentRecipient = [];
    if (this.getForm().value.stripePaymentRecipientId) {
      accountExt.paymentRecipient.push({ name: 'stripe', ref: this.getForm().value.stripePaymentRecipientId });
    }
    if (this.getForm().value.paypalPaymentRecipientId) {
      accountExt.paymentRecipient.push({ name: 'paypal', ref: this.getForm().value.paypalPaymentRecipientId });
    }
    account.paymentRecipient = accountExt.paymentRecipient;
    accountExt.paymentDirectCharges = this.getForm().value.paymentDirectCharges;
    accountExt.paymentDineInCommissionAmountMoney = new Money();
    accountExt.paymentDineInCommissionAmountMoney.amount = this.getForm().value.paymentDineInCommissionAmountMoney;
    accountExt.paymentDineInCommissionAmountMoney.currency = account.defaultCurrency;
    accountExt.paymentDineInCommissionPercent = this.getForm().value.paymentDineInCommissionPercent;
    accountExt.paymentPickupCommissionAmountMoney = new Money();
    accountExt.paymentPickupCommissionAmountMoney.amount = this.getForm().value.paymentPickupCommissionAmountMoney;
    accountExt.paymentPickupCommissionAmountMoney.currency = account.defaultCurrency;
    accountExt.paymentPickupCommissionPercent = this.getForm().value.paymentPickupCommissionPercent;
    accountExt.paymentShipmentCommissionAmountMoney = new Money();
    accountExt.paymentShipmentCommissionAmountMoney.amount = this.getForm().value.paymentShipmentCommissionAmountMoney;
    accountExt.paymentShipmentCommissionAmountMoney.currency = account.defaultCurrency;
    accountExt.paymentShipmentCommissionPercent = this.getForm().value.paymentShipmentCommissionPercent;
    accountExt.paymentTransactionFeeAmountMoney = new Money();
    accountExt.paymentTransactionFeeAmountMoney.amount = this.getForm().value.paymentTransactionFeeAmountMoney;
    accountExt.paymentTransactionFeeAmountMoney.currency = account.defaultCurrency;
    accountExt.paymentTransactionFeePercent = this.getForm().value.paymentTransactionFeePercent;
    accountExt.ordering = this.getForm().value.ordering;

    this.logEvent('account', account.id ? 'update' : 'add', account.name, account.id);
    account = await this.accountManager.addAccount(account);
    accountExt.id = account.id;
    await this.accountManager.addAccountExtension(accountExt);

    // update AccountImages if accountId not set
    for (const image of this.images) {
      if (!image.accountId && account.id) {
        image.accountId = account.id;
        await this.accountManager.addAccountImage(image);
      }
    }

    // TODO if ordering === false then disable location.orderingPickup and location.orderingDelivery

    await this.closeModal(account.id);
  }

  public async removeAccountPrompt(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant('ACCOUNT_DETAILS.CONFIRM_REMOVE'),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel',
          handler: async () => {
            await this.alertController.dismiss();
          }
        }, {
          text: this.translateService.instant('APP.OK'),
          handler: async () => {
            await this.removeAccount();
          }
        }
      ]
    });

    await alert.present();
  }

  public async removeAccount(): Promise<void> {
    const modal = await this.modalController.create({
      component: TextEditComponent,
      componentProps: {
        subHeader: this.translateService.instant('APP.CONFIRM_DELETE_TITLE'),
        message: this.translateService.instant('APP.CONFIRM_DELETE_TEXT'),
        input: ''
      },
      cssClass: 'edit-modal'
    });

    await modal.present();

    await modal.onDidDismiss().then(async (results) => {
      if (results.role === 'ok' && results.data) {
        if (results.data.trim() === environment.app.pin) {
          await this.showLoading();
          await this.accountManager.removeAccount(this.accountId);
          await this.accountManager.removeAccountExtension(this.accountId);

          this.logEvent('account', 'remove', undefined, this.accountId);

          await this.hideLoading();
          await this.closeModal();
        } else {
          await this.showError(this.translateService.instant('APP.CONFIRM_DELETE_ERROR_TITLE'), this.translateService.instant('APP.CONFIRM_DELETE_ERROR_TEXT'));
        }
      }
    });
  }
}
