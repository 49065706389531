/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';


/**
 * Directive for an image preview.
 */
@Directive({
  selector: '[appImagePreview]'
})
export class ImagePreviewDirective implements AfterViewInit {

  // tslint:disable-next-line:no-input-rename
  @Input('appImagePreview') public imageFile: File;

  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'src', window.URL.createObjectURL(this.imageFile));
  }
}
