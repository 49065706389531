/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';

import { AudioAccess } from '../access/audio-access';


/**
 * Class providing management methods for audio.
 */
@Injectable({
  providedIn: 'root'
})
export class AudioManager {

  /**
   * The default constructor.
   */
  constructor(
    private audioAccess: AudioAccess
  ) {
  }

  /**
   * Initializes the audio player on user interaction to enable auto-play.
   */
  public async initAudio(): Promise<void> {
    return this.audioAccess.initAudio();
  }

  /**
   * Preloads an audio asset.
   *
   * @param key the key of the preloaded audio asset
   * @param asset the path to the audio asset
   */
  public preload(key: string, asset: string): void {
    this.audioAccess.preload(key, asset);
  }

  /**
   * Plays a preloaded audio asset.
   *
   * @param key the key of the preloaded audio asset
   */
  public async play(key: string): Promise<void> {
    return this.audioAccess.play(key);
  }
}
