/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, concat, interval, Observable, of, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';

import * as moment from 'moment-timezone';
import { SwUpdate } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { User } from './shared/models/user';
import { Account } from './shared/models/account';
import { AccountExtension } from './shared/models/account-extension';
import { AccountImage } from './shared/models/account-image';
import { Location } from './shared/models/location';
import { PlanSubscription } from './shared/models/plan-subscription';
import { PricePlan } from './shared/models/price-plan';
import { Reservation } from './shared/models/reservation';
import { Order, OrderStatusConstants } from './shared/models/order';
import { AuthManager } from './shared/manager/auth-manager.service';
import { SessionManager } from './shared/manager/session-manager.service';
import { AnalyticsManager } from './shared/manager/analytics-manager.service';
import { PlanManager } from './shared/manager/plan-manager.service';
import { AccountManager } from './shared/manager/account-manager.service';
import { LocationManager } from './shared/manager/location-manager.service';
import { ReservationManager } from './shared/manager/reservation-manager.service';
import { OrderManager } from './shared/manager/order-manager.service';
import { AudioManager } from './shared/manager/audio-manager.service';
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { UserDetailsComponent } from './user/user-details/user-details.component';
import { ConsentUtils } from './shared/utils/consent-utils';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private wakeLock = null;

  private stop$: Subject<boolean> = new Subject<boolean>();
  public currentUser?: User;
  public currentAccount?: Account;
  public currentAccountId?: string;
  public currentAccountImages?: AccountImage[] = [];
  public accounts?: Account[];
  public appVersion: string = environment.app.version;
  public isDevMode: boolean = !environment.production;
  public numberOfNewReservations = 0;
  public numberOfNewOrders = 0;
  public selectedLanguageCode: string;

  private currentAccountId$: ReplaySubject<string | undefined> = new ReplaySubject(1);
  private locations$: ReplaySubject<Location[]> = new ReplaySubject(1);

  private forceLogoutAlertShown = false;
  private termsConsentPromptShown = false;

  private reservationAlertSubscription: Subscription;
  private orderAlertSubscription: Subscription;

  /**
   * The default constructor.
   */
  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private modalController: ModalController,
    public translateService: TranslateService,
    private alertController: AlertController,
    private authManager: AuthManager,
    private sessionManager: SessionManager,
    private analyticsManager: AnalyticsManager,
    private planManager: PlanManager,
    private accountManager: AccountManager,
    private locationManager: LocationManager,
    private reservationManager: ReservationManager,
    private orderManager: OrderManager,
    private audioManager: AudioManager
  ) {
    this.initializeApp();

    this.currentAccountId$.next(undefined);
    this.locations$.next([]);

    registerLocaleData(localeDe);
    translateService.setDefaultLang(environment.app.defaultLang);
    translateService.addLangs(['de', 'en']);

    this.audioManager.preload('reservationAlert', 'assets/audio/service-bell_daniel_simion.mp3');
    this.audioManager.preload('orderAlert', 'assets/audio/service-bell_daniel_simion.mp3');

    this.sessionManager.getValue(this.sessionManager.PREFERRED_LANGUAGE).pipe(
      takeUntil(this.stop$)
    ).subscribe((language: string) => {
      translateService.use(translateService.getLangs().includes(language) ? language : environment.app.defaultLang);
    });

    const preferredLanguage: string = navigator.language.substr(0, 2);
    this.selectedLanguageCode = translateService.getLangs().includes(preferredLanguage) ? preferredLanguage : environment.app.defaultLang;
    sessionManager.setValue(sessionManager.PREFERRED_LANGUAGE, preferredLanguage).then();

    authManager.startCheckingLoggedInUser().pipe(
      switchMap(() => {
        return combineLatest([
          sessionManager.getCurrentUser(),
          sessionManager.getAccounts(),
          sessionManager.getValue(sessionManager.CURRENT_ACCOUNT_ID).pipe(first()).toPromise()
        ]);
      }),
      takeUntil(this.stop$)
    ).subscribe(([currentUser, accounts, currentAccountId]) => {
      this.currentUser = currentUser;
      this.accounts = accounts;

      if (this.accounts) {
        if (this.accounts.length > 0) {
          if (currentAccountId && this.accounts.findIndex((account: Account) => account.id === currentAccountId) > -1) {
            this.currentAccountId$.next(currentAccountId);
          } else {
            this.currentAccountId$.next(this.accounts[0].id);
          }
        } else {
          this.currentAccountId$.next(undefined);
          this.forceLogout().then();
        }
      }
    });

    this.currentAccountId$.asObservable().pipe(
      switchMap((accountId: string | undefined) => {
        return accountId
          ? combineLatest([
            this.accountManager.getAccounts([accountId]).pipe(map((accounts: Account[]) => accounts.length > 0 ? accounts[0] : undefined)),
            this.accountManager.getAccountExtensions([accountId])
              .pipe(map((accountExts: AccountExtension[]) => accountExts.length > 0 ? accountExts[0] : undefined))
          ])
          : of([undefined, undefined]);
      }),
      switchMap(([currentAccount, currentAccountExt]) => {
        this.currentAccount = currentAccount;
        this.currentAccountId = currentAccount === undefined ? undefined : currentAccount.id;
        this.sessionManager.setCurrentAccount(this.currentAccount, currentAccountExt);

        if (currentAccountExt && (!currentAccountExt.termsVersion || currentAccountExt.termsVersion < ConsentUtils.ORDERYOYO_TERMS_VERSION)) {
          if (!this.termsConsentPromptShown) {
            this.termsConsentPromptShown = true;
            ConsentUtils.showTermsConsentPrompt(alertController, translateService, accountManager, currentAccountExt).then();
          }
        }

        return combineLatest([
          planManager.getAllPricePlans(),
          currentAccount ? planManager.getAllPlanSubscriptions(currentAccount.id) : of([]),
          currentAccount && currentAccount.imageIds && currentAccount.imageIds.length > 0
            ? accountManager.getAccountImage(currentAccount.imageIds[0]) : of(undefined),
          currentAccount ? this.locationManager.getAllLocationsOfAccount(currentAccount.id) : of ([])
        ]);
      }),
      takeUntil(this.stop$)
    ).subscribe((results: (PricePlan[] | PlanSubscription[] | AccountImage | Location[] | undefined)[]) => {
      const pricePlans: PricePlan[] = results[0] as PricePlan[];
      const planSubscriptions: PlanSubscription[] = results[1] as PlanSubscription[];
      this.currentAccountImages = results[2] ? [results[2] as AccountImage] : [];
      this.locations$.next(results[3] as Location[]);

      let limitLocations = 0;
      let limitMenus: number | null = 0;
      let limitCategories: number | null = 0;
      let limitItems: number | null = 0;

      for (const planSubscription of planSubscriptions) {
        const pricePlan: PricePlan | undefined = pricePlans.find((plan: PricePlan) => plan.id === planSubscription.pricePlanId);

        if (pricePlan !== undefined) {
          limitLocations += planSubscription.quantity;
          if (pricePlan.limits) {
            if (limitMenus !== null) {
              limitMenus = pricePlan.limits.menus === undefined ? null : limitMenus + planSubscription.quantity * pricePlan.limits.menus;
            }
            if (limitCategories !== null) {
              limitCategories = pricePlan.limits.categories === undefined
                ? null
                : (limitCategories < pricePlan.limits.categories ? pricePlan.limits.categories : limitCategories);
            }
            if (limitItems !== null) {
              limitItems = pricePlan.limits.items === undefined ? null : limitItems + planSubscription.quantity * pricePlan.limits.items;
            }
          }
        }
      }

      sessionManager.setLimitLocations(limitLocations);
      sessionManager.setLimitMenus(limitMenus);
      sessionManager.setLimitCategories(limitCategories);
      sessionManager.setLimitItems(limitItems);
    });

    // reservations
    this.locations$.asObservable().pipe(
      switchMap((locations: Location[]) => {
        const observables: Observable<Reservation[]>[] = [];

        locations.forEach((location: Location) => {
          observables.push(this.reservationManager.getAllReservations(location.id));
        });

        return observables.length > 0 ? combineLatest(observables) : of([]);
      }),
      takeUntil(this.stop$)
    ).subscribe((results: (Reservation[])[]) => {
      this.numberOfNewReservations = 0;

      results.forEach((result: Reservation[]) => result.forEach((entry: Reservation) => this.numberOfNewReservations += entry.status === 'request' ? 1 : 0));

      if (this.reservationAlertSubscription) {
        this.reservationAlertSubscription.unsubscribe();
        this.reservationAlertSubscription = undefined;
      }
      if (this.numberOfNewReservations > 0) {
        this.audioManager.play('reservationAlert').then().catch(() => {});

        this.sessionManager.getValue(this.sessionManager.RESERVATION_ALERT_REPEAT).pipe(first()).toPromise().then((repeatAlert: boolean | null) => {
          if (repeatAlert !== false) {
            this.reservationAlertSubscription = timer(10000, 10000).pipe(
              takeUntil(this.stop$)
            ).subscribe(() => {
              this.audioManager.play('reservationAlert').then().catch(() => {});
            });
          }
        });
      }
    });

    const fromTime: moment.Moment = moment().tz('Europe/Berlin').subtract(1, 'month');

    // orders
    this.locations$.asObservable().pipe(
      switchMap((locations: Location[]) => {
        const observables: Observable<Order[]>[] = [];

        locations.forEach((location: Location) => {
          // TODO activate if enabled status 'PLACED': observables.push(this.orderManager.getPlacedOnlyOrders(location.id));
          observables.push(this.orderManager.getPlacedOrders(location.accountId, location.id, fromTime.toISOString()));
        });

        return observables.length > 0 ? combineLatest(observables) : of([]);
      }),
      takeUntil(this.stop$)
    ).subscribe((results: (Order[])[]) => {
      this.numberOfNewOrders = 0;

      // TODO activate if enabled status 'PLACED': results.forEach((result: Order[]) => this.numberOfNewOrders += result.length);
      results.forEach((result: Order[]) => result.forEach((entry: Order) => {
        if (entry.status !== OrderStatusConstants.DEACTIVATED
          && entry.fulfillments && entry.fulfillments.length > 0 && entry.fulfillments[0].status === 'PROPOSED'
          && entry.tenders && entry.tenders.length > 0) {
          this.numberOfNewOrders++;
        }
      }));

      if (this.orderAlertSubscription) {
        this.orderAlertSubscription.unsubscribe();
        this.orderAlertSubscription = undefined;
      }
      if (this.numberOfNewOrders > 0) {
        this.audioManager.play('orderAlert').then().catch(() => {});

        this.sessionManager.getValue(this.sessionManager.ORDER_ALERT_REPEAT).pipe(first()).toPromise().then((repeatAlert: boolean | null) => {
          if (repeatAlert !== false) {
            this.orderAlertSubscription = timer(10000, 10000).pipe(
              takeUntil(this.stop$)
            ).subscribe(() => {
              this.audioManager.play('orderAlert').then().catch(() => {});
            });
          }
        });
      }
    });
  }

  private initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('#001036');
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
      }
    });

    // keep the screen awakt
    if ('wakeLock' in navigator) {
      // Screen Wake Lock API supported
      (async () => {
        try {
          // @ts-ignore
          this.wakeLock = await navigator.wakeLock.request('screen');
        } catch (err) {
          console.log(`${err.name}: ${err.message}`);
        }
      })();
    }
  }

  public ngOnInit(): void {
    if (this.swUpdate.isEnabled) {  // check for browsers not supporting ServiceWorkers
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const waitingPeriod$ = interval(60 * 1000);
      concat(appIsStable$, waitingPeriod$).pipe(
        takeUntil(this.stop$)
      ).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });

      this.swUpdate.available.pipe(
        takeUntil(this.stop$)
      ).subscribe(async () => {
        throw new Error('REQUEST_RELOAD');
      });
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
    if (this.orderAlertSubscription) {
      this.orderAlertSubscription.unsubscribe();
      this.orderAlertSubscription = null;
    }

    if (this.wakeLock) {
      this.wakeLock.release();
      this.wakeLock = null;
    }
  }

  public initAudioAlerts(): void {
    this.audioManager.initAudio().then().catch(() => {});
  }

  /**
   * Sets the account ID to use.
   *
   * @param accountId the selected account ID
   */
  public async useAccount(accountId: string): Promise<void> {
    await this.sessionManager.setValue(this.sessionManager.CURRENT_ACCOUNT_ID, this.currentAccountId, true);
    this.currentAccountId$.next(accountId);
  }

  /**
   * Sets the language to use.
   *
   * @param $event the ion-select CustomEvent
   */
  public useLanguage($event: CustomEvent): void {
    this.selectedLanguageCode = $event.detail.value;
    this.sessionManager.setValue(this.sessionManager.PREFERRED_LANGUAGE, $event.detail.value).then();
  }

  /**
   * Logs out the user.
   */
  public async logout(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant('APP.CONFIRM_LOGOUT'),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel',
          handler: async () => {
            await this.alertController.dismiss();
          }
        }, {
          text: this.translateService.instant('APP.OK'),
          handler: async () => {
            await this.authManager.signOut();
            await this.analyticsManager.logEvent('side_menu', undefined, 'user', 'logout');
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * Forces to log out the user.
   */
  private async forceLogout(): Promise<void> {
    if (this.currentUser && !this.forceLogoutAlertShown) {
      this.forceLogoutAlertShown = true;

      const alert = await this.alertController.create({
        message: this.translateService.instant('APP.CONFIRM_FORCE_LOGOUT'),
        buttons: [
          {
            text: this.translateService.instant('APP.OK'),
            handler: async () => {
              await this.authManager.signOut();
              await this.analyticsManager.logEvent('side_menu', undefined, 'user', 'logout');
            }
          }
        ],
        backdropDismiss: false
      });

      alert.onDidDismiss().then(() => this.forceLogoutAlertShown = false);

      await alert.present();
    }
  }

  public async createAccount(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: AccountDetailsComponent,
      backdropDismiss: false,
      cssClass: 'fullscreen-modal'
    });

    modal.onDidDismiss().then((results) => {
      if (results.data) {
        this.currentAccountId = results.data;
      }
    });

    return await modal.present();
  }

  public async startAccount(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: AccountDetailsComponent,
      componentProps: { accountId: this.currentAccount.id },
      backdropDismiss: false,
      cssClass: 'fullscreen-modal'
    });

    return await modal.present();
  }

  public async startUser(id: string): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: UserDetailsComponent,
      componentProps: { userId: id },
      backdropDismiss: false,
      cssClass: 'fullscreen-modal'
    });

    return await modal.present();
  }
}
