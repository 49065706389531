/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import { StorageImage } from '../../models/storage-image';
import { CloudStorageManager } from '../../manager/cloud-storage-manager.service';


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {

  public isHovering: boolean;

  @Input() public initialImage?: StorageImage;
  @Output() public changeImage: EventEmitter<StorageImage | undefined> = new EventEmitter<StorageImage | undefined>();

  public currentImage?: StorageImage;
  public isImageUploading = false;
  public currentFile: File;

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  constructor(
    public platform: Platform,
    private translateService: TranslateService,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private alertController: AlertController,
    private cloudStorageManager: CloudStorageManager
  ) {
  }

  async ngOnInit() {
    this.currentImage = this.initialImage ? this.initialImage : undefined;
  }

  public toggleHover(isHovering: boolean): void {
    this.isHovering = isHovering;
  }

  // Determines if the upload task is active
  public isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  public async selectImage(): Promise<void> {
    // open the file chooser in case of desktop browser
    if (!this.platform.is('cordova')) {
      this.startFileChooserAndUpload();

      return;
    }

    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: this.translateService.instant('UPLOAD_IMAGE.CAMERA'),
          icon: 'camera',
          handler: () => {
            this.startImageSelectorAndUpload(true);
          }
        }, {
          text: this.translateService.instant('UPLOAD_IMAGE.GALLERY'),
          icon: 'photos',
          handler: () => {
            this.startImageSelectorAndUpload(false);
          }
        }
      ]
    });

    await actionSheet.present();
  }

  private startImageSelectorAndUpload(useCamera: boolean): void {
    // open the file chooser in case of desktop browser
    if (!this.platform.is('cordova')) {
      const element: HTMLElement = document.getElementById('fileInput') as HTMLElement;
      element.click();

      return;
    }

    const options: CameraOptions = {
      quality: 80,
      targetWidth: 1600,
      targetHeight: 1600,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: useCamera ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY,
      allowEdit: true
    };

    this.camera.getPicture(options).then(async (imageData) => {
      this.isImageUploading = true;
      this.currentImage = new StorageImage();
      this.currentImage.url = 'data:image/jpeg;base64,' + imageData;

      this.currentImage = await this.cloudStorageManager.uploadImageByDataUrl(this.currentImage.url);
      this.changeImage.emit(this.currentImage);
      this.isImageUploading = false;
    }, (err) => {
      // Handle error
      console.log('Camera issue:' + err);
    });
  }

  /**
   * Starts the native file chooser.
   * After selecting a file the upload process begins by calling the uploadFile() method.
   */
  private startFileChooserAndUpload(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * Uploads a file.
   *
   * @param fileList the file list containing the file to upload
   */
  public async uploadFile(fileList: FileList): Promise<void> {
    if (fileList.length > 0) {
      this.isImageUploading = true;
      this.currentFile = fileList.item(0);

      this.currentImage = await this.cloudStorageManager.uploadImage(this.currentFile);
      this.changeImage.emit(this.currentImage);
      this.currentFile = undefined;
      this.isImageUploading = false;
    }
  }

  public async deleteImage(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant('UPLOAD_IMAGE.CONFIRM_REMOVE'),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel',
          handler: async () => {
            await this.alertController.dismiss();
          }
        }, {
          text: this.translateService.instant('APP.OK'),
          handler: async () => {
            this.fileInput.nativeElement.value = '';
            this.changeImage.emit(undefined);

            this.currentImage = undefined;
            this.currentFile = undefined;
          }
        }
      ]
    });

    await alert.present();
  }
}
