/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */

/**
 * Represents a physical address.
 */
export class Address {
  /**
   * The first line of the address.
   * Fields that start with address_line provide the address's most specific details, like street number, street name, and building name.
   * They do not provide less specific details like city, state/province, or country (these details are provided in other fields).
   */
  public addressLine1?: string;

  /** The second line of the address, if any. */
  public addressLine2?: string;

  /** The third line of the address, if any. */
  public addressLine3?: string;

  /** The city or town of the address. */
  public locality?: string;

  /** A civil region within the address's locality, if any. */
  public sublocality?: string;

  /** A civil region within the address's sublocality, if any. */
  public sublocality2?: string;

  /** A civil region within the address's sublocality_2, if any. */
  public sublocality3?: string;

  /** A civil entity within the address's country. In the US, this is the state. */
  public administrativeDistrictLevel1?: string;

  /** A civil entity within the address's administrative_district_level_1. In the US, this is the county. */
  public administrativeDistrictLevel2?: string;

  /** A civil entity within the address's administrative_district_level_2, if any. */
  public administrativeDistrictLevel3?: string;

  /** The address's postal code. */
  public postalCode?: string;

  /** The address's country, in ISO 3166-1-alpha-2 format. See Country for possible values. */
  public country?: string;

  /** The address's country name. */
  public countryName?: string;

  /** Optional first name when it's representing recipient. */
  public firstName?: string;

  /** Optional last name when it's representing recipient. */
  public lastName?: string;

  /** Optional organization name when it's representing recipient. */
  public organization?: string;

  public lat?: number;
  public lng?: number;
}
