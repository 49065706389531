/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {

  public isHovering: boolean;

  @Output() public changeFile: EventEmitter<File | undefined> = new EventEmitter();

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  constructor(
  ) {
  }

  public toggleHover(isHovering: boolean): void {
    this.isHovering = isHovering;
  }

  public async selectFile(): Promise<void> {
    this.startFileChooserAndUpload();
  }

  /**
   * Starts the native file chooser.
   * After selecting a file the upload process begins by calling the uploadFile() method.
   */
  private startFileChooserAndUpload(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * Uploads a file.
   *
   * @param fileList the file list containing the file to upload
   */
  public async uploadFile(fileList: FileList): Promise<void> {
    if (fileList.length > 0) {
      this.changeFile.emit(fileList.item(0));
      this.fileInput.nativeElement.value = '';
    }
  }
}
