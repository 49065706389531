/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
