/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { LanguageDictionary } from '../models/language-dictionary';


/**
 * Utility class for language dictionary operations.
 */
export class DictUtils {

  /**
   * Sets the text associated with the given key and language code.
   *
   * @param dicts the language dictionaries
   * @param key the key of the string resource
   * @param lang the language code in ISO-639-1 format
   * @param text the text to set
   */
  static setString(dicts: LanguageDictionary[] | undefined, key: string, lang: string, text: string): void {
    if (dicts !== undefined && key) {
      let dict: LanguageDictionary | undefined = dicts.find((dictionary: LanguageDictionary) => dictionary.lang === lang);
      if (dict === undefined) {
        dict = new LanguageDictionary();
        dict.lang = lang;
        dict.sourceType = 'OWNER';
        dicts.push(dict);
      }
      dict.translations[key] = text;
    }
  }

  /**
   * Returns the text associated with the given key and language code.
   * If no text is found then the return the key as fallback.
   *
   * @param dicts the language dictionaries
   * @param key the key of the string resource
   * @param lang the language code in ISO-639-1 format
   * @param optional true if to ignore fallback
   * @returns the found text, otherwise undefined
   */
  static getString(dicts: LanguageDictionary[] | undefined, key: string, lang: string, optional: boolean = false): string | null | undefined {
    let text: string | null | undefined;

    if (dicts !== undefined && key) {
      const dict: LanguageDictionary | undefined = dicts.find((dictionary: LanguageDictionary) => dictionary.lang === lang);
      if (dict !== undefined && dict.translations && dict.translations.hasOwnProperty(key)) {
        text = dict.translations[key];
      }
    }

    if (text === undefined && !optional) {
      text = key;
    }

    return text;
  }

  /**
   * Returns the language dictionaries comprising the given keys.
   *
   * @param dicts the language dictionaries
   * @param keys the key of the string resources
   * @returns the found language dictionaries, otherwise empty
   */
  static getStrings(dicts: LanguageDictionary[], keys: string[]): LanguageDictionary[] {
    const foundDicts: LanguageDictionary[] = [];

    if (dicts && dicts.length > 0 && keys && keys.length > 0) {
      for (const dictionary of dicts) {
        if (dictionary.translations) {
          const foundDict: LanguageDictionary = new LanguageDictionary();
          foundDict.sourceType = dictionary.sourceType;
          foundDict.lang = dictionary.lang;
          foundDict.translations = {};
          let foundEntry = false;

          for (const key of keys) {
            if (dictionary.translations.hasOwnProperty(key)) {
              foundDict.translations[key] = dictionary.translations[key];
              foundEntry = true;
            }
          }

          if (foundEntry) {
            foundDicts.push(foundDict);
          }
        }
      }
    }

    return foundDicts;
  }

  /**
   * Returns all owner translations associated with the given key. The default language is not included.
   * All entries have the format e.g. 'de:GERMANTEXT'
   *
   * @param dicts the language dictionaries
   * @param key the key of the string resource
   * @param defaultLangToIgnore the code of the language in ISO-639-1 format to ignore
   * @returns the found texts, otherwise empty
   */
  static getOwnerTranslations(dicts: LanguageDictionary[] | undefined, key: string, defaultLangToIgnore: string): string[] {
    const translations: string[] = [];

    if (dicts !== undefined && key) {
      for (const dict of dicts) {
        if (dict.sourceType === 'OWNER' && dict.lang !== defaultLangToIgnore && dict.translations.hasOwnProperty(key)) {
          translations.push(`${dict.lang}:${dict.translations[key]}`);
        }
      }
    }

    return translations;
  }

  /**
   * Returns the language dictionaries excluding the given keys.
   *
   * @param dicts the language dictionaries
   * @param keys the key of the string resources
   * @returns the cleaned language dictionaries
   */
  static removeStrings(dicts: LanguageDictionary[], keys: string[]): LanguageDictionary[] {
    const foundDicts: LanguageDictionary[] = [];

    if (dicts && dicts.length > 0) {
      for (const dictionary of dicts) {
        if (dictionary.translations) {
          const foundDict: LanguageDictionary = new LanguageDictionary();
          foundDict.sourceType = dictionary.sourceType;
          foundDict.lang = dictionary.lang;
          foundDict.translations = {};
          let foundEntry = false;

          for (const key of Object.keys(dictionary.translations)) {
            if (!keys || !keys.includes(key)) {
              foundDict.translations[key] = dictionary.translations[key];
              foundEntry = true;
            }
          }

          if (foundEntry) {
            foundDicts.push(foundDict);
          }
        }
      }
    }

    return foundDicts;
  }

  /**
   * Merges language dictionaries to other ones.
   *
   * @param fromDicts the language dictionaries to merge from
   * @param toDicts the language dictionaries to merge to
   */
  static merge(fromDicts: LanguageDictionary[], toDicts: LanguageDictionary[]): LanguageDictionary[] {
    const mergedDicts: LanguageDictionary[] = toDicts ? JSON.parse(JSON.stringify(toDicts)) : [];

    if (fromDicts && fromDicts.length > 0) {
      for (const fromDict of fromDicts) {
        if (fromDict.translations) {
          const toDict: LanguageDictionary | undefined = mergedDicts.find((dictionary: LanguageDictionary) => dictionary.lang === fromDict.lang);

          if (toDict !== undefined && toDict.translations) {
            for (const key of Object.keys(fromDict.translations)) {
              toDict.translations[key] = fromDict.translations[key];
            }
          } else {
            mergedDicts.push(JSON.parse(JSON.stringify(fromDict)));
          }
        }
      }
    }

    return mergedDicts;
  }
}
