/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { first, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { Customer } from '../models/customer';
import { Account } from '../models/account';
import { UserManager } from './user-manager.service';
import { CustomerManager } from './customer-manager.service';
import { AccountManager } from './account-manager.service';
import { SessionManager } from './session-manager.service';

import * as firebase from 'firebase/app';
import AuthCredential = firebase.auth.AuthCredential;
import UserCredential = firebase.auth.UserCredential;
import ConfirmationResult = firebase.auth.ConfirmationResult;


/**
 * Class providing management methods for authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthManager {

  private currentFirebaseUser: firebase.User | null;

  /**
   * The default constructor.
   */
  constructor(
    private afAuth: AngularFireAuth,
    private sessionManager: SessionManager,
    private userManager: UserManager,
    private customerManager: CustomerManager,
    private accountManager: AccountManager,
    private navController: NavController,
    private router: Router
  ) {
  }

  /**
   * Starts checking the logged in user.
   *
   * @returns the logged-in user
   */
  public startCheckingLoggedInUser(): Observable<void> {
    return this.afAuth.authState.pipe(
      switchMap((firebaseUser: firebase.User | null) => {
        this.currentFirebaseUser = firebaseUser;

        return (!firebaseUser || (environment.auth.useEmailVerification && !firebaseUser.emailVerified))
          ? of(undefined)
          : this.userManager.getUser(firebaseUser.uid);
      }),
      switchMap((user: User | undefined) => {
        this.sessionManager.setCurrentUser(user);

        // TODO remove hardcoded URL checking
        if (!user && !this.router.url.startsWith('/order/edit/') && !this.router.url.startsWith('/reservation/edit/')) {
          this.navController.navigateRoot('/login/login').then();
        }

        if (user) {
          if (user.isAdmin) {
            return this.accountManager.getAllAccounts();
          } else if (user.accountIds.length > 0) {
            return this.accountManager.getAccounts(user.accountIds);
          } else {
            return of([]);
          }
        } else {
          return of(undefined);
        }
      }),
      map((accounts: Account[] | undefined) => {
        this.sessionManager.setAccounts(accounts);
        this.sessionManager.setReady();
      })
    );
  }

  public isAnonymous(): boolean {
    return this.currentFirebaseUser ? this.currentFirebaseUser.isAnonymous : false;
  }

  public async registerUserByEmail(params: {
    nickName: string,
    email: string,
    password: string,
    salutation: string,
    firstname: string,
    lastname: string,
    accountIds: string[],
    phoneNumber?: string
  }): Promise<User> {
    const credential: UserCredential = await this.afAuth.createUserWithEmailAndPassword(params.email, params.password);

    let user: User = new User();
    user.id = credential.user.uid;
    user.createdAt = new Date().toISOString();
    user.nickName = params.nickName;  // credential.user.nickName;
    user.email = credential.user.email;
    user.photoUrl = credential.user.photoURL;
    user.salutation = params.salutation;
    user.firstname = params.firstname;
    user.lastname = params.lastname;
    user.accountIds = params.accountIds;
    user.phoneNumber = params.phoneNumber ?? null;

    user = await this.userManager.addUser(user);

    if (environment.auth.useEmailVerification) {
      await credential.user.sendEmailVerification();
    }

    return user;
  }

  public async requestPassword(userEmail: string): Promise<void> {
    await this.afAuth.sendPasswordResetEmail(userEmail);
  }

  public async doEmailLogin(userEmail: string, userPassword: string): Promise<User> {
    const credential: UserCredential = await this.afAuth.signInWithEmailAndPassword(userEmail, userPassword);

    if (environment.auth.useEmailVerification && !credential.user.emailVerified) {
      await this.afAuth.signOut();
      return Promise.reject(new Error('AUTH.VERIFY_EMAIL'));
    }

    let user: User | undefined = await this.userManager.getUser(credential.user.uid).pipe(first()).toPromise();

    // in case there is already a customer but no user with the same email address
    if (user === undefined) {
      const customer: Customer | undefined = await this.customerManager.getCustomer(credential.user.uid).pipe(first()).toPromise();
      if (customer !== undefined) {
        user = new User();
        user.id = credential.user.uid;
        user.createdAt = new Date().toISOString();
        user.nickName = customer.firstname;  // credential.user.nickName;
        user.email = credential.user.email;
        user.photoUrl = credential.user.photoURL;

        user = await this.userManager.addUser(user);
      } else {
        await this.afAuth.signOut();
        return Promise.reject(new Error('AUTH.MIGRATE_FROM_CUSTOMER'));
      }
    }

    return user;
  }

  public async doGuestLogin(userNickName: string): Promise<User> {
    const credential: UserCredential = await this.afAuth.signInAnonymously();

    let user: User = new User();
    user.id = credential.user.uid;
    user.createdAt = new Date().toISOString();
    user.nickName = userNickName;
    user.email = credential.user.email;
    user.photoUrl = credential.user.photoURL;

    user = await this.userManager.addUser(user);

    return user;
  }

  public async upgradeUser(userEmail: string, userPassword: string): Promise<User> {
    const authCredential: AuthCredential = firebase.auth.EmailAuthProvider.credential(userEmail, userPassword);
    const currentUser: firebase.User = await this.afAuth.currentUser;
    const credential: UserCredential = await currentUser.linkWithCredential(authCredential);

    let user: User = await this.userManager.getUser(currentUser.uid).pipe(first()).toPromise();
    user.email = credential.user.email;

    user = await this.userManager.updateUser(user);

    return user;
  }

  public async setPassword(email: string, oldPassword: string, newPassword: string): Promise<void> {
    const currentUser: firebase.User = await this.afAuth.currentUser;
    const credential: AuthCredential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);

    await currentUser.reauthenticateWithCredential(credential).catch(_ => { throw new Error('AUTH.INVALID_CREDENTIALS'); });
    await currentUser.updatePassword(newPassword).catch(_ => { throw new Error('AUTH.INVALID_NEW_PASSWORD'); });
  }

  public async requestLoginCode(phoneNumber: string): Promise<void> {
    try {
//      const appVerifier = this.windowRef.recaptchaVerifier;
//      const confirmationResult: ConfirmationResult = await this.afAuth.auth.signInWithPhoneNumber(phoneNumber, appVerifier);
    } catch (error) {
      console.log(error);
    }
  }

  public async verifyLoginCode(confirmationResult: ConfirmationResult, verificationCode: string, nickName: string): Promise<User> {
    const credential: UserCredential = await confirmationResult.confirm(verificationCode);

    const user: User = new User();
    user.id = credential.user.uid;
    user.createdAt = new Date().toISOString();
    user.nickName = nickName;
    user.email = credential.user.email;
    user.photoUrl = credential.user.photoURL;

    return this.userManager.addUser(user);
  }

  public async signOut() {
    await this.afAuth.signOut();
  }
}
